import { ReducerObject } from '../../types/reducerTypes';
import { AccessGraphState } from './state';

export const ACCESS_GRAPH_ACTIONS = {
  SET_ACCESS_GRAPH: 'SET_ACCESS_GRAPH',
};

export const accessGraphObjectReducer: ReducerObject<AccessGraphState> = {
  [ACCESS_GRAPH_ACTIONS.SET_ACCESS_GRAPH]: (state, action) => {
    return {
      ...state,
      accessGraph: action.accessGraph
    }
  }
}