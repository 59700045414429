import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {DataHandlerStoreBlueprint} from '@stores/DataHandlerStore';
import {useAccessGraphSetters, useAccessGraphState} from 'reducer/accessGraph';
import {getAccessGraph} from 'api/accessGraph';
import './style.scss';

interface Props {
    DataHandlerStore?: DataHandlerStoreBlueprint | any;
    DataTableStore?: any;
    StudyStore?: any;
}

const DropDown = () => {
    const {accessGraph} = useAccessGraphState();
    const {setAccessGraph} = useAccessGraphSetters();
    const [clientDrop, setClientDrop] = useState(true);
    const [studyDrop, setStudyDrop] = useState(false);
    const [clientTest, setClientTest] = useState(false);
    const [clientArr, setClientArr] = useState<any[]>([]);
    const [studyArr, setStudyArr] = useState<any[]>([]);

    let history = useHistory();
    const studyClient: any = localStorage.getItem('studyClient');

    let studyTest: any = localStorage.getItem('studyClient');
    let itemPapa: any = localStorage.getItem('item');

    if (studyTest) {
        studyTest = JSON.parse(studyTest).clientId;
    }

    if (itemPapa) {
        itemPapa = JSON.parse(itemPapa).studyId;
    }

    useEffect(() => {
        if (studyArr) {
            const trialStudiesWithoutHub = studyArr[0]?.studies.reduce((studies, study) => {
                if (study.studyType === 'trial') studies.push({ id: study.studyId });
                return studies;
            }, []);

            if (trialStudiesWithoutHub) {
                localStorage.setItem('olderStudies', JSON.stringify(trialStudiesWithoutHub));
            }
        }
    }, [studyArr])

    useEffect(() => {
        if (accessGraph && accessGraph.clients.length >= 2) {
            setClientDrop(true)
        }

        if (studyTest) {
            setClientTest(studyTest)
        }

        if (accessGraph) {
            setClientArr(accessGraph.clients)
            setStudyArr(accessGraph.clients)

            if (studyClient === '') {
                localStorage.setItem('studyClient', accessGraph.clients[0].name);
            }

            accessGraph.clients.map((item: any, i: number) => {
                if (item.studyList.length >= 2) {
                    setStudyDrop(true)
                }
            })
        }


        if (!accessGraph) {
            (async () => {
                const res = await getAccessGraph(true, true);
                setAccessGraph(res);
            })()
        }
    }, [accessGraph])

    const Add = clientArr.map((Add: any, i: number) => Add);
    const AddStudy = studyArr.map((AddStudy: any, i: number) => AddStudy);

    const handleClientArrChange = (e) => {
        if (accessGraph) {
            const item = clientArr[e.target.value];
            setClientTest(item.clientId);
            const firstStudyForClient = item.studyList[0];
            localStorage.setItem('item', JSON.stringify(firstStudyForClient));
            localStorage.setItem('studyClient', JSON.stringify(item));
            history.push(`/candidates/${firstStudyForClient.studyId}/${firstStudyForClient.firstTagCategory}`, {...item});
        }
    }

    const handleStudyArrChange = (e) => {
        if (accessGraph) {
            const item = clientTest ? studyArr.filter(cl => cl.clientId === clientTest) : studyArr;

            const studyItem = item[0].studyList.filter(cl => cl.studyId === e.target.value)[0];

            if (studyItem) {
                localStorage.setItem('item', JSON.stringify(studyItem))
                history.push(`/candidates/${studyItem.studyId}/${studyItem.firstTagCategory}`, {...studyItem});
            }
        }
    }

    return <>
        {clientDrop && accessGraph &&
            <div className="study-dd"><span className="label">Sponsor:</span>
                <span className="input_container">
          <span className="fancy-select">
            {
                <select defaultValue={`${studyTest && studyTest}`} onChange={e => handleClientArrChange(e)} data-cy="nav_sponsor-dropdown">
                    {Add.map((item: any, i: number) => {
                        return <option key={i}
                                       data-cy={`nav_sponsor-dropdown_${item.name}-option`}
                                       value={studyTest === item.clientId ? item.clientId : i}>{item.name}</option>
                    })}
                </select>
            }
          </span>
        </span>
            </div>
        }

        {studyDrop && accessGraph &&
            <div className="study-dd"><span className="label">Study:</span>
                <span className="input_container">
          <span className="fancy-select">
              {
                  <select defaultValue={itemPapa && itemPapa} onChange={e => handleStudyArrChange(e)} data-cy="nav_study-dropdown">
                      {clientTest ?
                          AddStudy.filter(cl => cl.clientId === clientTest)[0].studyList.map((item: any, i: number) => {
                              return <option key={i} data-cy={`nav_study-dropdown_${item.name}-option`} value={item.studyId}>{item.name}</option>
                          })
                          :
                          Add[0].studyList.map((item: any, i: number) => {
                              return <option key={i} data-cy={`nav_study-dropdown_${item.name}-option`} value={item.studyId}>{item.name}</option>
                          })
                      }
                  </select>
              }
          </span>
          </span>
            </div>
        }
    </>
};

@inject('DataHandlerStore')
@observer
class DropDowns extends React.Component<Props, {}> {

    renderSuccess = () => {

        return <><DropDown/></>
    }

    render() {
        const {DataHandlerStore: {renderContent}} = this.props;
        return (
            <>
                {
                    (renderContent)
                        ? this.renderSuccess()
                        : <div/>
                }
            </>
        )
    }
}

export default DropDowns;
