import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScaleLoader } from 'react-spinners';
import './style.scss';

interface Props {
  DataHandlerStore?: any;
}

interface State {
  loading: boolean;
}

const { Fragment } = React;
@inject('DataHandlerStore')
@observer
class Loader extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  render() {
    const { DataHandlerStore: { fetchingContent } } = this.props;
    return (
      <Fragment>
        {fetchingContent && <div className="loading_container">
          <div className="sweet-loading">
            <ScaleLoader
              // sizeUnit={'px'}
              // size={60}
              color={'#349b91'}
              loading={fetchingContent}
            />
          </div>
        </div>}
      </Fragment>
    );
  }
}
export default Loader;
