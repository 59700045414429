const { REACT_APP_API_URL } = process.env;
const token = localStorage.getItem('token');

export default async function request (method: 'GET' | 'POST', url: string, body: any = {}, headers: Record<string, string> = {}) {
  const head = { 'Authorization': `Bearer ${token}` }
  try { 
    if (method === 'POST') {
      head['Content-Type'] = 'application/json';
    }

    const res = await fetch(`${REACT_APP_API_URL}${url}`, {
      method: method,
      headers: { ...head, ...headers },
      body: Object.keys(body).length !== 0 ? JSON.stringify(body) : undefined
    }).then(res => res.json());

    return res;
  } catch (err) {
    console.log(err);
    return null;
  }
}