import * as React from 'react';

interface Props {
  children: any;
  style: any;
  width: string;
  className: string;
  viewBox: string;
  role: string;
}

// @TODO: There is definitely a better way to deal with icons!
const { Fragment } = React;

const Path = {
  /* eslint-disable */
  add: <Fragment><path fill="currentColor" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  arrow: <Fragment><path fill="currentColor" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" /><path fill="none" d="M0 0h24v24H0z" /></Fragment>,
  candidate: <Fragment><path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  candidates: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" /></Fragment>,
  check: <Fragment><path fill="none" d="M0 0h24v24H0z" /><path fill="currentColor" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></Fragment>,
  close: <Fragment><path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  comment: <Fragment><path fill="currentColor" d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  cross: <Fragment><path fill="currentColor" d="M23.8,19.3C23.8,19.3,23.8,19.3,23.8,19.3L16.5,12l7.3-7.3c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.3,0-0.6-0.2-0.8 l-3.4-3.4C20.1,0,19.8-0.1,19.5,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0L12,7.5L4.7,0.2c0,0,0,0,0,0C4.6,0.1,4.5,0.1,4.5,0 C4.2-0.1,3.9,0,3.7,0.2L0.2,3.7C0,3.9-0.1,4.2,0,4.5c0,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0L7.5,12l-7.3,7.3c0,0,0,0,0,0 c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.3,0,0.6,0.2,0.8l3.4,3.4C3.9,24,4.2,24.1,4.5,24c0.1,0,0.2-0.1,0.3-0.2c0,0,0,0,0,0l7.3-7.3 l7.3,7.3c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0.3,0.1,0.6,0,0.8-0.2l3.4-3.4c0.2-0.2,0.3-0.5,0.2-0.8 C23.9,19.5,23.9,19.4,23.8,19.3L23.8,19.3z"/></Fragment>,
  delete: <Fragment><path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  edit: <Fragment><path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  error: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /></Fragment>,
  home: <Fragment><path fill="currentColor" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  info: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" /></Fragment>,
  loading: <Fragment><path fill="currentColor" d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  location: <Fragment><path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" /><path d="M0 0h24v24H0z" fill="none" /></Fragment>,
  lock: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" /></Fragment>,
  phone: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" /></Fragment>,
  save: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z" /></Fragment>,
  settings: <Fragment><path fill="none" d="M0 0h20v20H0V0z" /><path fill="currentColor" d="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34c-.03-.2-.2-.34-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" /></Fragment>,
  study: <Fragment><path d="M0 0h24v24H0z" fill="none" /><path fill="currentColor" d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" /></Fragment>,
  default: <Fragment><path></path></Fragment>,
  /* eslint-enable */
};

// Define the SVG markup
const SVG: React.SFC<Props> = ({
  children = '',
  style = {},
  width = '24px',
  className = '',
  viewBox = '0 0 24 24',
  role = '',
}) => (
    <svg
      role={role || 'presentation'}
      width={width}
      style={style}
      height={width}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ''}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {children}
    </svg>
  );

const Icon = (props) => {
  const { name } = props;
  let iconName;
  switch (name) {
    case 'view':
      iconName = 'arrow';
      break;
    case 'call-centre':
    case 'call-center':
      iconName = 'phone';
      break;
    case 'neutral':
      iconName = 'info';
      break;
    case 'warning':
    case 'failure':
      iconName = 'error';
      break;
    case 'done':
    case 'tick':
    case 'success':
      iconName = 'check';
      break;
    default:
      iconName = (Path.hasOwnProperty(props.name)) ? props.name : 'default';
  }
  return <SVG {...props}>{Path[iconName]}</SVG>;
};

export default Icon;
