import {CANDIDATE_STATUS_EXCLUDED} from '../candidateStatus';

const French = {
  pages: {
    login: {
      title: 'Accéder à votre compte',
    },
    dashboard: {
      title: 'tableau de bord',
      subNavigation: {
        summary: 'Les Sommaire',
        conversions: 'La Transformation',
      },
    },
    users: {
      title: 'tous les utilisateurs',
    },
    candidates: {
      title: 'tous les candidats',
      button: {
        create: {
          label: 'ajouter un nouveau candidat.',
          copy: 'ajouter un nouveau candidat',
        },
      },
    },
    candidates_create: {
      title: 'ajouter un nouveau candidat',
    },
    candidates_edit: {
      title: 'modifier les données du candidat',
    },
    investigators: {
      title: 'tous les enquêteurs',
      button: {
        create: {
          label: 'ajouter un nouvel enquêteur.',
          copy: 'ajouter un nouvel enquêteur',
        },
      },
    },
    investigators_create: {
      title: 'ajouter un nouvel enquêteur',
    },
    investigators_edit: {
      title: 'modifier les données du enquêteur',
    },
    clients: {
      title: 'All Clients',
      button: {
        create: {
          label: 'Add a new client.',
          copy: 'Add a new client',
        },
      },
    },
    clients_create: {
      title: 'Add a new client',
    },
    clients_edit: {
      title: 'Edit client data',
    },
    sites: {
      title: 'tous les lieux',
      button: {
        create: {
          label: 'ajouter un nouveau lieu.',
          copy: 'ajouter un nouveau lieu',
        },
      },
    },
    studies: {
      title: 'toutes les études',
      button: {
        create: {
          label: 'ajouter une nouvelle étude.',
          copy: 'ajouter une nouvelle étude',
        },
      },
    },
    'call-centres': {
      title: "tous les centres d'appels",
      button: {
        create: {
          label: "ajouter un nouveau centre d'appels.",
          copy: "ajouter un nouveau centre d'appels",
        },
      },
    },
    'my-profile': {
      title: 'Mon profil',
    },
    fourohfour: {
      title: "Alors! Cette page n'existe pas.",
      copy: "Vous semblez avoir trouvé une page qui n'existe pas. Essayez d'utiliser le menu pour naviguer.",
    },
  },
  actions: {
    add: 'Ajouter',
    edit: 'Modifier',
    delete: 'Effacer',
    view: 'Vue',
    submit: 'Soumettre',
    save: 'Sauvgardner',
    cancel: 'Annuler',
  },
  statuses: {
    registered: 'Registered',
    preScreened: 'Pre-screened',
    bookedScreening: 'Booked screening',
    consented: 'Consented',
    enrolled: 'Enrolled',
    excluded: CANDIDATE_STATUS_EXCLUDED,
  },
  headers: {
    age: 'Age',
    campaignContent: 'Contenu de la campagne',
    campaignMedium: 'Milieu de campagne',
    campaignName: 'Nom de la campagne',
    campaignSource: 'Source de la campagne',
    campaignTerm: 'Terme de campagne',
    contactNumber: 'Numéro de téléphone',
    created: 'Date / heure de soumission',
    dateApplied: 'Date appliquée',
    dob: 'Date de naissance',
    email: 'Adresse email',
    firstName: 'prénom',
    formQuestionResponses: 'Questions',
    id: 'ID du candidat',
    ivrsCode: 'code IVRS',
    notes: 'Notes',
    screeningQuestionResponses: 'questions de dépistage',
    screenVisitDate: 'Date de visite de l\'écran',
    gender: 'gendere',
    status: 'Statut',
    studySiteId: 'Site préféré',
    surname: 'Nom de famille',
    utmData: 'données UTM',
    websiteUrl: 'Site Web',
  },
  formFields: {
    contactNumber: {
      description: 'We need this to contact you',
      placeholder: '0161 225 1234',
    },
    county: {
      description: '',
      placeholder: 'Lancashire',
    },
    created: {
      description: '',
      placeholder: '',
    },
    dateApplied: {
      description: '',
      placeholder: '',
    },
    dob: {
      description: 'We need this to place you in a relevant study',
      placeholder: 'Jan 20th 1979',
    },
    email: {
      description: 'We need this to contact you',
      placeholder: 'firstname.lastname@domain.tld',
    },
    firstName: {
      description: 'We need this to help identify you',
      placeholder: 'John',
    },
    formQuestionResponses: {
      description: '',
      placeholder: '',
    },
    id: {
      description: 'We need this to help identify you',
      placeholder: '',
    },
    ivrsCode: {
      description: '',
      placeholder: '1-234-56',
    },
    notes: {
      description: 'Lorem ipsum dolor sit amet...',
      display: 'Add new note',
      placeholder: 'Lorem ipsum dolor sit amet...',
    },
    postcode: {
      description: 'May be used to assist delivery',
      placeholder: 'M1 7EP',
    },
    studySiteId: {
      description: '',
      placeholder: '',
    },
    region: {
      description: 'May be used to assist delivery',
      placeholder: 'FR',
    },
    screeningQuestionResponses: {
      description: '',
      placeholder: '',
    },
    screenVisitDate: {
      description: '',
      placeholder: '',
    },
    gender: {
      description: 'We need this to place you in a relevant study',
      placeholder: '',
      options: {
        male: 'Male',
        female: 'Female',
      },
    },
    status: {
      description: '',
      placeholder: '',
    },
    streetAddressFirst: {
      description: 'Street and number, PO Box, c/o',
      placeholder: '54 Champs Elysée',
    },
    streetAddressSecond: {
      description: 'Flat, suite, unit, building, floor, etc',
      placeholder: 'Lx Labs, First Floor',
    },
    study: {
      description: '',
      placeholder: '',
    },
    surname: {
      description: 'We need this to help identify you',
      placeholder: 'Macron',
    },
    townCity: {
      description: '',
      placeholder: 'Paris',
    },
    utmData: {
      description: '',
      display: 'thomasxbanks',
      placeholder: '',
    },
  },
  messages: {
    required: 'Vous devez répondre à cette question ',
    zero_records: 'Il n\'y a pas de résultats. S\'il vous plaît, re-filtrez.',
    missing_data: 'TBC',
    login: {
      before: 'Log in to your account.',
      during: 'Logging in. Please wait.',
    },
    logout: {
      confirm: 'Are you sure you want to log out? There is no undo on this option - you will need to log back in.',
      yes: 'Oui',
      no: 'Non',
    },
  },
};

export default French;
