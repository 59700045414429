import { observable, action } from 'mobx';
import DataHandlerStore from './DataHandlerStore';
import { csvEndpoint } from '@helpers/config';

const defaultText = `Drag 'n' drop some files here, or click to select files`;
const successText = `Successfully uploaded file. ${defaultText} again.`;

export class UploadStoreBlueprint {
  @observable canUpload: boolean = false;
  @observable dropZoneText: string = defaultText;

  @action
  updateUploadStatus = (newStatus: boolean) => this.canUpload = newStatus;

  @action
  bulkUpload = async ({ body, params }) => {
    await DataHandlerStore.post(csvEndpoint.csvBulkUpload.name, body, params)
    this.updateDropZoneText(successText);
    this.resetAll();
  }

  @action
  updateDropZoneText = (newText) => {
    this.dropZoneText = newText;
  }

  @action
  resetAll = () => {
    this.canUpload = false;
  }

}
const UploadStore = new UploadStoreBlueprint();
export default UploadStore;
