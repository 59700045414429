import { ReducerAction, ReducerObject } from '../types/reducerTypes';

export default function createReducerFromObject<T>(reducerObject: ReducerObject<T>) {
	return (state: T, action: ReducerAction<T>) => {
		if (!Object.keys(reducerObject).includes(action.type)) {
			throw new Error(`Unhandled type: ${action.type}`);
		} else {
			return reducerObject[action.type](state, action);
		}
	};
}
