import { observable, action, computed } from 'mobx';
import {getValue, slugify, slugifyRetainCase} from '@helpers/util';
import BaseStore from './BaseStore';
import CandidateStore from './CandidateStore';
import _ from 'underscore';
import { CANDIDATE_STATUS_EXCLUDED } from '../@helpers/candidateStatus';

export class DataTableStoreBlueprint extends BaseStore {
    @observable activeOption: any = {};
    @observable selectedOption: any = {};
    @observable selectedPage: number = 0;
    @observable stateFilters: any;
    @observable data: any = { rows: Array<any>(), headers: Array<any>() };
    @observable loading: any = [];
    @observable tabIndex: number = 0;
    @observable showCaption: boolean = true;
    @observable directionIsAZ: boolean = true;

    @action
    load = async (tableData: any, filters: any) => {
        this.stateFilters = filters;
        this.data = tableData;
    };

    @action
    setActiveOption = (activeOption: any) => {
        this.activeOption = activeOption;
    };

    @action
    setSelectedFilter = async (filterType: any, value: any) => {
        this.selectedOption[filterType] = value;

        if (filterType === 'priorityStatus') {
            this.selectedOption['status'] = 'all';
        }

        if (filterType === 'status') {
            this.selectedOption['priorityStatus'] = 'all';
        }
    };

    @action
    resetSelectedFilter = () => {
        Object.keys(this.selectedOption).forEach((key) => {
            if (key === 'date-applied') {
                this.selectedOption[key] = 'desc';
            } else {
                this.selectedOption[key] = 'all'
            }
        });
    };

    @action
    resetSelectedPage = () => {
        this.selectedPage = 0;
    }

    @computed
    get totalRowsRecords() {
        return (this.data && this.data.rows) ? this.data.rows.length : 0;
    }

    @computed
    get totalHeadersRecords() {
        return (this.data && this.data.headers) ? this.data.headers.length : 0;
    }

    @computed
    get computedFilters() {
        const mutableStateFilters = {};
        if (!this.stateFilters || !this.stateFilters.length) {
            return [];
        }

        this.stateFilters.forEach((filter) => {
            switch (filter.value) {
                case 'status':
                    mutableStateFilters[filter.value] = CandidateStore.FilterStatus;
                    break;
                case 'date-applied':
                    const dateSorts = [{ value: slugify('desc'), display: 'Descending' }, { value: slugify('asc'), display: 'Ascending' } ];
                    mutableStateFilters[filter.value] = dateSorts;
                    break;
                case 'priorityStatus':
                    mutableStateFilters[filter.value] = CandidateStore.FilterPriority;
                    break;
                case 'siteIdentifier':
                    mutableStateFilters[filter.value] = CandidateStore.FilterSiteIdentifier;
                    break;
                case 'siteRegion':
                    mutableStateFilters[filter.value] = CandidateStore.FilterRegion;
                    break;
                case 'country':
                    mutableStateFilters[filter.value] = CandidateStore.FilterCountry;
                    break;
                case 'utmSource':
                    mutableStateFilters[filter.value] = CandidateStore.FilterUTMSource;
                    break;
                case 'utmMedium':
                    mutableStateFilters[filter.value] = CandidateStore.FilterUTMMedium;
                    break;
                case 'utmFormat':
                    mutableStateFilters[filter.value] = CandidateStore.FilterUTMFormat;
                    break;
                case 'lastUpdated':
                    const lastUpdatedSorts = [
                        { value: slugify('24hours'), display: 'Last 24 hours' },
                        { value: slugify('48hours'), display: 'Last 48 hours' },
                        { value: slugify('72hours'), display: 'Last 72 hours' },
                        { value: slugify('7days'), display: 'Last 7 days' } ];
                    mutableStateFilters[filter.value] = lastUpdatedSorts;
                    break;
                case 'enabled':
                    if (this.data && this.data.rows) {
                        const unique = _.uniq((this.data.rows.map(obj => {
                            return { value: obj.enabled.value, display: obj.enabled.value }
                        })), (item) => {
                            return item.value;
                        })
                        mutableStateFilters[filter.value] = unique;
                    }
                    break;
                default:
                    break;
            }
        });
        return mutableStateFilters;
    }

    hasFilter = (filters: any, filterName: string) => {
        if (!filters) {
            return false;
        }

        return filters.some((theFilter) => {
            return theFilter.value === filterName;
        })
    }
}

const DataTableStore = new DataTableStoreBlueprint();
export default DataTableStore;
