import { request } from '../utils';

export const getAccessGraph = async (ignoreWhiteList: boolean, returnNoSites: boolean) => {
    // tslint:disable-next-line:no-return-await
  return await request('GET', `/api/user/access-graph/${ignoreWhiteList ? 'yes' : 'no'}/${returnNoSites ? 'yes' : 'no'}`);
}

export const getCacheAccessGraph = async (accessGraph, setAccessGraph) => {
  if (!accessGraph) {
    const res = await getAccessGraph(true, false);
    setAccessGraph(res);
    return res;
  } else {
    return accessGraph;
  }
}
