import { action, observable } from 'mobx';
import _ from 'underscore';

import DataTableStore from './DataTableStore';
import DataHandlerStore from './DataHandlerStore';
import ROUTES from '@constants/routes';
import BaseStore from './BaseStore';
import UserStore from './UserStore';
import { SitesModel } from './@StoreModels';
import StudyStore from './StudyStore';
import { studySiteEndpoint } from '@helpers/config';
import { convertToTable } from '../@helpers/util';

export class SitesStoreBlueprint extends BaseStore {
    @observable allStudiesWithProps: any = {};
    @observable canAddNewSites: boolean = false;
    @observable data: any = Array<any>();
    @observable dataAsTable: any = Array<any>();
    @observable defaultStudyId: any = null;
    @observable showSelectionModal: boolean = false;
    @observable sitesList: any = {};
    @observable selectedSites: any = [];
    @observable selectedCountries: any = [];
    @observable studySites: any = [];
    @observable siteOnSelect: any = [];
    @observable selectedStudy: any = {};

    filterObject: any = {};

    @action
    create = async (body: SitesModel) => {
        const response: any = await DataHandlerStore.post(studySiteEndpoint.createStudySite.name, body);
        const data = response.data;
        this.current = data;
        this.data = [];
        return Promise.resolve(data);
    };

    @action
    editCurrentSelection = async (access) => {
        await this.populateSitesWithDefault(access);
        this.onShowModal();
    }

    @action
    filterSitesByCountrySelection = ({ countriesSelected }) => {
        const { sites } = this.selectedStudy;
        const filteredSelectedCountries = sites && sites.filter(item => countriesSelected.includes(item.countryCode));
        this.selectedCountries = _.groupBy(filteredSelectedCountries, (item) => {
            return item.countryName;
        });
    };

    @action
    getSites = async () => {
        let apiCall;
        apiCall = DataHandlerStore.get(studySiteEndpoint.getStudySites.name);
        const response: any = await apiCall;
        const { data } = response;
        this.data = data;
        this.dataAsTable = convertToTable(data);
        await this.populateSitesWithDefault();
        return Promise.resolve(data);
    };

    @action
    getSite = async (id: string) => {
        const response: any = await DataHandlerStore.get(studySiteEndpoint.getStudySite.name, id);
        const { data } = response;
        this.current = data;
        return Promise.resolve(data);
    };

    @action
    getNewSite = async () => {
        const response: any = await DataHandlerStore.get(studySiteEndpoint.getNewSite.name);
        const site = response.data;
        this.current = site;
        return Promise.resolve(site);
    };

    @action
    resetFilters = async () => {
        DataTableStore.resetSelectedPage();
        DataTableStore.resetSelectedFilter();
        await this.getSites();
        DataTableStore.data = this.dataAsTable;
    }

    @action
    filterBy = async (type, filterValue: string) => {
        DataTableStore.resetSelectedPage();
        if (!type || !filterValue) {
            await this.resetFilters()
            return
        }
        this.filterObject[type] = filterValue;
        await this.load('', this.filterObject);
        DataTableStore.data = this.dataAsTable;
    }

    @action
    applyFilterEnabled = async (filter: any) => {
        const filterKeys: Array<string> = Object.keys(filter);
        if (filterKeys.includes('enabled')) {
            await this.getSites();
            switch (filter.enabled) {
                case 'Active':
                    this.data = this.data.filter(obj => {
                        return obj.enabled.value === 'Active'
                    })
                    this.dataAsTable = convertToTable(this.data)
                    return;
                case 'Deactivated':
                    this.data = this.data.filter(obj => {
                        return obj.enabled.value === 'Deactivated'
                    })
                    this.dataAsTable = convertToTable(this.data)
                    return;
                default:
                    break;
            }
        }
    }

    @action
    load = async (id: string, filter: any = {}) => {
        this.resetAllState();
        if (Object.keys(filter).length !== 0) {
            await this.applyFilterEnabled(filter);
            return;
        }
        if (!id) {
            if (!(this.data && this.data.length > 0)) {
                await this.getSites();
                this.siteOnSelect = [];
            }
            return;
        }
        if (id === 'new') {
            await this.getNewSite();
        } else {
            await this.getSite(id);
        }
    };

    @action
    navigateEdit = (history: any, id: string) => {
        this.navigate(history, ROUTES.SITES.constsructEdit(id));
    };

    @action
    onShowModal = () => {
        this.onOpeningModal();
        this.populateStudyList();
    };

    @action
    onOpeningModal = () => {
        this.showSelectionModal = true;
    };

    @action
    onCloseModal = () => {
        this.showSelectionModal = false;
    };

    @action
    resetOnSelect = () => {
        this.siteOnSelect = [];
    };

    @action
    resetAllState = () => {
        this.resetOnSelect();
        this.selectedSites = [];
        this.selectedCountries = [];
        this.selectedStudy = {};
        this.canAddNewSites = false;
        this.resetOnSelect();
    };

    @action
    remove = async (id: string) => {
        const response: any = await DataHandlerStore.delete(studySiteEndpoint.deleteStudySite.name, id);
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    saveSiteListSelection = (selection) => {
        const filtered: any = this.getCheckedsites({ selection });
        if (this.alreadyInSiteAccess(this.selectedStudy.studyId)) {
            const curr = UserStore.siteAccess.filter(item => item.relatedId === this.selectedStudy.studyId);
            UserStore.siteAccess.remove(curr[0])
            UserStore.siteAccess.unshift({ relatedId: this.selectedStudy.studyId, sites: filtered, studyName: this.selectedStudy.name });
        } else {
            UserStore.siteAccess.push({ relatedId: UserStore.currentAccess.relatedId, sites: filtered, studyName: this.selectedStudy.name });
        }
        this.updateBaseAccess({ array: filtered });
        this.showCanAddNewSites();
        this.onCloseModal();
    };

    @action
    update = async (body: SitesModel) => {
        const response = await DataHandlerStore.post(studySiteEndpoint.updateStudySite.name, body);
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    removeSelectedStudySites = (siteAccess) => {
        if (UserStore.siteAccess && UserStore.siteAccess.length) {
            UserStore.siteAccess.remove(siteAccess);
        }
    }

    showCanAddNewSites = () => this.canAddNewSites = true;

    // Private methods
    alreadyInSiteAccess = (key: string) => UserStore.siteAccess.some(access => access.relatedId === key);

    getSitesByStudyId = async (id: string) => {
        this.resetAllState();
        const response: any = await DataHandlerStore.get(studySiteEndpoint.getSitesByStudyId.name, id);
        const { data } = response;
        this.siteOnSelect = data;
        if (data && data.length) {
            this.selectedStudy = this.allStudiesWithProps[id];
            UserStore.currentAccess = Object.assign(UserStore.currentAccess, {
                relatedId: this.selectedStudy.studyId
            });

            // no sites associated with study, save study to access
            if (!this.selectedStudy.hasSites) {
                StudyStore.saveStudyToSelection(this.selectedStudy.studyId);
            }
        }
    };

    getCheckedsites = ({ selection }) => {
        const filtered: any = [];
        if (!Object.keys(selection).length) {
            return filtered;
        }
        const sites = this.selectedCountries;
        Object.keys(selection).forEach(key => {
            const selected = selection[key];
            if (selected.checked) {
                Object.keys(sites).forEach(countryName => {
                    const site = sites[countryName];
                    const isContainedWithin = site.some(item => item.siteId === key);
                    if (isContainedWithin) {
                        filtered.push({ siteId: selected.value, name: selected.name, countryName })
                    }
                });
            }
        });
        this.selectedSites = filtered;
        return filtered;
    }

    updateSiteAccess = (result) => {
        const currentAccessValue = this.currentAccess.value;
        if (currentAccessValue && currentAccessValue.length) {
            UserStore.currentAccess = currentAccessValue[0];
            delete UserStore.currentAccess.clientId;
            const { sites } = UserStore.currentAccess;
            UserStore.currentAccess.sites = sites.map(item => ({ relatedId: item.relatedId }));

            Object.keys(result).forEach(key => {
                const current = result[key];

                currentAccessValue.forEach((access) => {
                    const sites = current.sites.filter(currentSite => access.sites.some(site => site.relatedId === currentSite.siteId))
                    if (access.relatedId === key && !this.alreadyInSiteAccess(key)) {
                        UserStore.siteAccess.push({ relatedId: key, sites, studyName: current.name });
                    }
                })
            });
        }
    }

    populateStudyList = () => {
        const { current: { sitesList } } = UserStore;
        if (!sitesList || !sitesList.value || !sitesList.value.data) {
            return;
        }
        const siteListValue = sitesList.value;
        const { data } = siteListValue;
        let result = {};
        data.forEach(item => {
            const { studies } = item;
            if (studies && studies.length) {
                studies.forEach(study => {
                    result[`${study.studyId}`] = { ...study }
                });
            }
        });

        this.updateSiteAccess(result)

        if (UserStore.siteAccess.length) {
            this.showCanAddNewSites();
        }
        this.allStudiesWithProps = result;
    };

    populateSitesWithDefault = async (access: any = {}) => {
        this.getAccess(UserStore);
        const hasAccess = this.currentAccess && this.currentAccess.hasAccess;
        this.populateStudyList();
        const { clientId } = UserStore.current;
        if (hasAccess && this.currentAccess.value) {
            const hasClientId = clientId && Object.keys(clientId).length;
            if (hasClientId) {
                this.defaultStudyId = this.currentAccess.value[0].relatedId;
                await StudyStore.getByClientId(clientId);
            }
            const accessValue = this.currentAccess.value[0];

            if (!accessValue.sites) {
                return;
            }
            const { relatedId } = accessValue;
            const hasAccessValue = Object.keys(access).length;
            if (hasAccessValue) {
                this.selectedStudy = this.allStudiesWithProps[access.relatedId];
            } else {
                this.selectedStudy = this.allStudiesWithProps[relatedId];
            }

            if (!this.selectedStudy) {
                return;
            }

            const { sites, countries } = this.selectedStudy;
            const filteredBasedOnAccess: any = hasAccessValue ?
                this.currentAccess.value.filter(item => item.relatedId === access.relatedId)[0] :
                this.currentAccess.value[0];

            const sitesFromAPI = _.uniq(filteredBasedOnAccess.sites.map(item => item.relatedId));
            this.selectedSites = sites.filter(item => sitesFromAPI.includes(item.siteId));

            const countriesSelected = countries.map(item => item.countryCode);
            this.filterSitesByCountrySelection({ countriesSelected });
        }
    };

    updateBaseAccess = ({ array }) => {
        let sites = [];
        if (array) {
            sites = array.map(item => ({ relatedId: item.siteId }));
        }

        UserStore.currentAccess = Object.assign(UserStore.currentAccess, {
            sites,
            hasAccess: true
        });
    }
}

const SitesStore = new SitesStoreBlueprint();
export default SitesStore;
