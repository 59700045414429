import * as React from 'react';
import { inject, observer } from 'mobx-react';

import * as Helper from '@helpers';

import { InfoPanelStoreBlueprint } from '@stores/InfoPanelStore';

import './style.scss';

interface Props {
    showInfoPanel?: boolean | false;
    ref?: any;
    InfoPanelStore?: InfoPanelStoreBlueprint | any;
}

const { Component: Component, Fragment } = React;

@inject('InfoPanelStore')
class InfoPanel extends Component<Props, {}> {

    panelRef: any = React.createRef();
    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', Helper.debounce(this.handleResize, 50));
    }

    UNSAFE_componentWillReceiveProps(previousProps) {
        const { showInfoPanel } = this.props;
        if (previousProps.showInfoPanel !== showInfoPanel) {
            this.handleResize();
        }
    }

    componentWillUnmount() {
        document.documentElement.style.overflow = '';
        window.removeEventListener('resize', Helper.debounce(this.handleResize, 50));
    }

    handleResize = () => {
        const { InfoPanelStore: { resize, masthead } } = this.props;
        if (masthead && this.panelRef.current) {
            const screenHeight = document.documentElement.clientHeight;
            const mastheadHeight = masthead.clientHeight;
            const offsetTop = Math.round(this.panelRef.current.getBoundingClientRect().top);
            const calculatedHeight = screenHeight - offsetTop;
            resize(calculatedHeight, mastheadHeight);
        }
    };

    render() {
        const { showInfoPanel, children, InfoPanelStore: { height, top } } = this.props;
        return (
            <Fragment>
                <aside
                    ref={this.panelRef}
                    className="info-panel_container"
                    data-open={showInfoPanel}
                    style={{ height, top }}
                >
                    <div
                        className="info-panel_content_container"
                        ref={this.panelRef}
                    >
                        {children}
                    </div>
                </aside>
            </Fragment>
        );
    };
}

export default InfoPanel;
