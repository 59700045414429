import { observable, action } from 'mobx';

import DataHandlerStore from './DataHandlerStore';
import { convertToTable } from '@helpers/util';
import BaseStore from './BaseStore';
import ROUTES from '@constants/routes';
import { clientsEndpoint } from '@helpers/config';

export interface ClientModel {
    id?: string;
    name: string;
    contactName: string;
    contactEmail: string;
}

class ClientStore extends BaseStore {
    @observable data: any = [];
    @observable dataAsTable: any = [];
    @observable current: any = {};

    @action
    load = async (id: string) => {
        if (id) {
            this.current = {};
            await this.getClient(id);
            return;
        }
        const response: any = await DataHandlerStore.get(clientsEndpoint.getClients.name);
        const { data } = response;
        this.data = data;
        this.dataAsTable = convertToTable(data);
    }

    @action
    create = async (body: ClientModel) => {
        const response: any = await DataHandlerStore.post(clientsEndpoint.createClient.name, body);
        this.data = [];
        return Promise.resolve(response);
    }

    @action
    getClient = async (id: string) => {
        const response: any = await DataHandlerStore.get(clientsEndpoint.getClientById.name, id);
        this.current = response.data;
        return Promise.resolve(response);
    }

    getNew = async () => {
        const { data }: any = await DataHandlerStore.get(clientsEndpoint.getNewClient.name);
        this.current = data;
    };

    @action
    navigateEdit = (history: any, id: string) => {
        this.navigate(history, ROUTES.CLIENTS.constsructEdit(id));
    }

    @action
    remove = async (id: string) => {
        const response: any = await DataHandlerStore.delete(clientsEndpoint.deleteClient.name, id);
        this.data = [];
        return Promise.resolve(response);
    }

    @action
    update = async (body: ClientModel) => {
        const response: any = await DataHandlerStore.post(clientsEndpoint.updateClient.name, body);
        this.data = [];
        return Promise.resolve(response);
    }

}

export default new ClientStore();
