import Copy from '../@helpers/copy';
import ROUTES from './routes';
import {
    ROLE_ADMIN,
    ROLE_CC_USER,
    ROLE_CLIENT,
    ROLE_INVESTIGATOR,
    ROLE_SITE_MANAGER,
    ROLE_SUPERUSER,
    ROLE_TPDP
} from '../@helpers/RoleTypes';

const allClients = {
    url: ROUTES.CLIENTS.index,
    copy: Copy.pages.clients.title,
    label: Copy.pages.clients.view,
    icon: 'candidates',
};

const allUsers = {
    url: ROUTES.USERS.index,
    copy: Copy.pages.users.title,
    label: Copy.pages.users.view,
    icon: 'candidates',
};

export const myCandidates = {
    url: '/',
    copy: Copy.pages.mycandidates.title,
    label: Copy.pages.mycandidates.view,
    icon: 'candidates',
};

export const csvUpload = {
    url: '/csv-upload',
    copy: Copy.pages.csvUpload.title,
    label: Copy.pages.csvUpload.view,
    icon: 'upload',
};

const allSites = {
    url: ROUTES.SITES.index,
    copy: Copy.pages.sites.title,
    label: Copy.pages.sites.view,
    icon: 'location',
};

// const allStudies = {
//     url: ROUTES.STUDIES.index,
//     copy: Copy.pages.studies.title,
//     label: Copy.pages.studies.view,
//     icon: 'study',
// };

const allCallCentres = {
    url: ROUTES.CALLCENTRES.index,
    copy: Copy.pages['call-centres'].title,
    label: Copy.pages['call-centres'].view,
    icon: 'phone',
};

const menuItems = {
    superUser: [allClients, allSites, allUsers, allCallCentres],
    admin: [allSites, allUsers, allCallCentres],
    callcentre: [myCandidates],
    investigators: [myCandidates],
    client: [myCandidates],
    siteManagers: [myCandidates],
    thirdParty: []
};

export const getMenu = (role: any) => {
    switch (role) {
        case ROLE_ADMIN:
          return menuItems.admin;
        case ROLE_SUPERUSER:
            return menuItems.superUser;
        case ROLE_CC_USER:
            return menuItems.callcentre;
        case ROLE_INVESTIGATOR:
            return menuItems.investigators;
        case ROLE_CLIENT:
            return menuItems.client;
        case ROLE_SITE_MANAGER:
            return menuItems.siteManagers;
        case ROLE_TPDP:
            return menuItems.thirdParty;
    }
};
