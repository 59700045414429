import React, {useEffect, useState} from 'react';
import './_style.scss';
import '../../scss/components/body_content.scss'

const Footer = (props: any) => {
    let currentUser: any = localStorage.getItem('token');

    if (currentUser !== null) {

        return (
            <div className={'footer'}>
                <div className={'container'}>
                    <a href={'/privacy-policy'}>Privacy Policy</a>
                </div>
            </div>
        )
    } else {
        return (
            <></>
        );
    }
};

export default Footer;
