export const reducer = (state, action) => {
  switch (action.type) {
    case 'ERROR':
      return {
        ...state,
        errors: action.errors
      };
    case 'DISMISS_ERROR':
      return {
        ...state,
        errors: [],
        option: {
          type: null,
          message: null,
          payload: null
        },
      }
    case 'OPTION':
      return {
        ...state,
        option: action.option
      }
    case 'DISMISS_OPTION':
      return {
        ...state,
        option: {
          type: null,
          message: null,
          payload: null
        },
      }
    default:
      return state;
  }
};
