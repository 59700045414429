import { observable, action } from 'mobx';

export class ErrorBoundaryStoreBluePrint {
  @observable error: any;
  @observable errorInfo: any;

  @action
  updateErrorBoundary = (error: any, errorInfo: any) => {
    this.error = error;
    this.errorInfo = errorInfo;
  }

}

const ErrorBoundaryStore = new ErrorBoundaryStoreBluePrint();
export default ErrorBoundaryStore;
