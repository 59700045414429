import DataHandlerStore from '../@stores/DataHandlerStore';
import { candidateEndpoint } from '../@helpers/config';

export class StatusFactory {
    static async getStatuses (studyId) {
        const baseKey: string = 'static-status-'
        const key: string = `${baseKey}${studyId}`;
        const lsValue = localStorage.getItem(key);

        if (lsValue) {
            return JSON.parse(lsValue);
        } else {
            const response: any = await DataHandlerStore.get(candidateEndpoint.getStatusList.name, studyId);
            const { data } = response;
            try {
                localStorage.setItem(key, JSON.stringify(data));
            } catch (error) {
                for (let i = 0; i < localStorage.length; i++) {
                    if (localStorage.key[i].includes(baseKey)) {
                        localStorage.removeItem(localStorage.key[i]);
                    }
                }
                localStorage.setItem(key, JSON.stringify(data));
            }

            return data;
        }
    }

    static async getPriorityStatuses (studyId) {
        const currentUser: any = localStorage.getItem('current-user');
        const role = currentUser.role;
        const baseKey: string = 'static-priority-'
        const key: string = `${baseKey}${studyId}-${role}`;
        const lsValue = localStorage.getItem(key);

        if (lsValue) {
            return JSON.parse(lsValue);
        } else {
            const response: any = await DataHandlerStore.get(candidateEndpoint.getPriorityList.name, studyId);
            const { data } = response;
            try {
                localStorage.setItem(key, JSON.stringify(data));
            } catch (error) {
                for (let i = 0; i < localStorage.length; i++) {
                    if (localStorage.key[i].includes(baseKey)) {
                        localStorage.removeItem(localStorage.key[i]);
                    }
                }
                localStorage.setItem(key, JSON.stringify(data));
            }

            return data;
        }
    }
}
