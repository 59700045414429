import AuthStore from './AuthStore';
import BaseStore from './BaseStore';
import CallCentreStore from './CallCentreStore';
import CandidateStore from './CandidateStore';
import ClientStore from './ClientStore';
import DataHandlerStore from './DataHandlerStore';
import DataTableStore from './DataTableStore';
import EntityStore from './EntityStore';
import ErrorBoundaryStore from './ErrorBoundaryStore';
import InfoPanelStore from './InfoPanelStore';
import MenuStore from './MenuStore';
import NotificationStore from './NotificationStore';
import SitesStore from './SitesStore';
import StudyStore from './StudyStore';
import TabStore from './TabStore';
import UserStore from './UserStore';
import UploadStore from './UploadStore'

export default {
  AuthStore,
  BaseStore,
  CallCentreStore,
  CandidateStore,
  ClientStore,
  DataHandlerStore,
  DataTableStore,
  EntityStore,
  ErrorBoundaryStore,
  InfoPanelStore,
  MenuStore,
  NotificationStore,
  SitesStore,
  StudyStore,
  TabStore,
  UserStore,
  UploadStore
};
