import Moment from 'moment';
import _ from 'underscore';
import Config from './config';
import Copy from './copy';
import {
    ROLE_ADMIN,
    ROLE_CC_USER,
    ROLE_CLIENT,
    ROLE_INVESTIGATOR,
    ROLE_SITE_MANAGER,
    ROLE_SUPERUSER,
    ROLE_TPDP
} from './RoleTypes';

interface SanitisedDataModel {
    headers: any | undefined;
    rows: Array<any>;
}

export const slugify = (value) => {
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }
    return value.toString().replace(/ /g, '-').toLowerCase();
};

export const slugifyRetainCase = (value) => {
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }
    return value.toString().replace(/ /g, '-');
};

export const format = (value, withTime = false) => {
    let formattedValue;
    let type;
    // Define the type of formatting needed
    if (value === null || value === undefined) {
        value = Copy.messages.missing_data;
    }

    if (Moment(value, Moment.ISO_8601, true).isValid() && value.length === 7) {
        type = 'monthdate';
    } else if (Moment(value, Moment.ISO_8601, true).isValid()) {
        type = 'date';
    } else if (value.toString().match(/^[0-9.,-]+$/)) {
        type = 'id';
    } else if (['m','f','nc', 'pnts', 'nb'].includes(value)) {
        type = 'gender';
    } else if (Object.keys(Copy.statuses).indexOf(value.toString()) > -1) {
        type = 'status';
    } else if (Array.isArray(value)) {
        type = 'array';
    } else {
        type = 'string';
    }

    // Format the data based on the type
    if (type === 'gender') {
        switch (value) {
            case 'f':
            case 'F':
                formattedValue = Copy.formFields.gender.options.female;
                break;
            case 'm':
            case 'M':
                formattedValue = Copy.formFields.gender.options.male;
                break;
            case 'nc':
                formattedValue = Copy.formFields.gender.options.notcollected;
                break;
            case 'pnts':
                formattedValue = Copy.formFields.gender.options.pnts;
                break;
        }
    } else if (type === 'string') {
        formattedValue = value.toString();
    } else if (type === 'date' && withTime) {
        formattedValue = Moment(value).format(Config.dateFormatWithTime);
    } else if (type === 'date') {
        formattedValue = Moment(value).format(Config.dateFormat);
    } else if (type === 'monthdate') {
        formattedValue = Moment(value).format('MMM, YYYY');
    } else if (type === 'status') {
        formattedValue = Copy.statuses[value];
    } else {
        formattedValue = value;
    }
    return formattedValue;
};

export const formatTotal = input => ({
    value: input,
    display: input.toLocaleString('en-GB'),
});

export const sanitiseHeaders = (returnedDataFromApi) => {
    returnedDataFromApi = Array.isArray(returnedDataFromApi) ? returnedDataFromApi[0] : returnedDataFromApi;
    const sanitisedHeaders = Array<any>();

    if (!returnedDataFromApi) {
        return sanitisedHeaders;
    }
    Object.keys(returnedDataFromApi).forEach((key) => {
        sanitisedHeaders.push({
            slug: key,
            display: returnedDataFromApi[key].display,
        });
    });

    return sanitisedHeaders.filter(item => (item.display));
};

export const sanitiseData = (returnedDataFromApi) => {
    if (returnedDataFromApi && returnedDataFromApi.length <= 0) {
        return {
            headers: sanitiseHeaders(returnedDataFromApi),
            rows: new Array<any>(),
        };
    }
    const sanitisedData: SanitisedDataModel = {
        headers: sanitiseHeaders(returnedDataFromApi),
        rows: new Array<any>(),
    };

    // Do something with each object in the array
    returnedDataFromApi = Array.isArray(returnedDataFromApi) ? returnedDataFromApi : [returnedDataFromApi];
    returnedDataFromApi.forEach((object: any) => {
        const tempObj = object;

        Object.keys(object).forEach((key) => {
            if (object[key].value === null || object[key].value === undefined) {
                // tempObj[key].slug = slugify(Copy.messages.missing_data);
                // tempObj[key].value = '';
                // tempObj[key].display = format(Copy.messages.missing_data);
            } else if (Array.isArray(object[key].value)) {
                tempObj[key].slug = slugify(object[key].display);
                tempObj[key].display = format(object[key].value);
            } else if (typeof object[key].value === 'object') {
                tempObj[key].slug = slugify(object[key].display);
            } else {
                tempObj[key].slug = object[key].display;
                tempObj[key].display = format(object[key].value);
            }
        });
        sanitisedData.rows.push(tempObj);
    });

    return sanitisedData;
};

export const actionButtons = (input: any) => {
    const actionButtonsObject = {
        candidates: ['view'],
        investigators: ['view'],
        clients: ['view'],
        users: ['view'],
        sites: ['view'],
        studies: ['view'],
        callCentres: ['view'],
        admins: ['view'],
    };

    return actionButtonsObject[input];
};

export const filters = (input: any) => {
    const mutableFilters = {
        maps: ['country'],
        candidates: ['date-applied', 'status', 'siteIdentifier', 'priorityStatus', 'country', 'lastUpdated'],
        investigator: ['date-applied', 'status', 'priorityStatus', 'lastUpdated'],
        waitingRoom: ['date-applied', 'country', 'lastUpdated'],
        client: ['status', 'siteIdentifier', 'siteRegion', 'country', 'utmSource', 'utmMedium', 'utmFormat'],
        investigators: ['status'],
        studies: ['enabled'],
        sites: ['enabled'],
        users: ['enabled']
    };
    const filters = mutableFilters[input];
    if (!filters) {
        return;
    }
    return filters.map(value => ({
        value,
        display: Config.headers[value],
        data: [],
    }));

};

export const headerOrder = (input: any) => {
    if (input === undefined) {
        input = 'default';
    }
    const headerOrderObject = {
        /* eslint-disable */
        candidates: ['id', 'condition', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'created', 'dateApplied', 'status', 'contactNumber', 'notes'],
        candidates_edit: ['id', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'email', 'phone', 'status', 'notes', 'websiteResponses', 'screenResponses', 'studyId', 'studySiteId', 'utmData', 'created', 'siteVisitDate'],
        clients: ['name', 'contactName', 'contactEmail'],
        clients_edit: ['name', 'contactName', 'contactEmail'],
        extract: ['id', 'firstName', 'surname', 'gender', 'dob', 'email', 'contactNumber', 'country', 'postcode'],
        investigators: ['id', 'firstName', 'surname', 'email', 'contactNumber', 'studySiteId'],
        investigators_edit: ['id', 'firstName', 'surname', 'email', 'contactNumber', 'studySiteId'],
        studies: ['id', 'status'],
        default: ['id', 'ivrsCode', 'firstName', 'surname', 'dob', 'gender', 'created', 'dateApplied', 'status', 'contactNumber', 'notes'],
        /* eslint-enable */
    };
    return headerOrderObject[input];
};

export const sortObject = (obj, page) => {
    if (!obj) {
        return undefined;
    }
    const sorting = headerOrder(page).map(h => h);
    const newSort = Array<any>();
    const keys = Object.keys(obj);
    const sorted = keys.map((item) => {
        const n = sorting.indexOf(item);
        sorting[n] = '';
        return [n, item];
    })
        .sort((a, b) => a[0] - b[0])
        .map((j) => { newSort.push(j); return (j[0] !== -1) ? j[1] : ''; })
        .filter(value => Object.keys(value).length !== 0);
    const result = {};
    sorted.forEach((item) => {
        result[item] = obj[item];
    });
    return result;
};

export const sortHeaders = (items, page) => {
    if (!items) {
        return undefined;
    }
    const sorting = headerOrder(page).map(h => h);
    const newSort = Array<any>();
    return items.map((item) => {
        const n = sorting.indexOf(item.slug);
        sorting[n] = '';
        return [n, item];
    })
        .sort((a, b) => a[0] - b[0])
        .map((j) => {
            newSort.push(j);
            return (j[0] !== -1) ? j[1] : '';
        })
        .filter(value => Object.keys(value).length !== 0);
};

export const sanitiseLegend = (textArray, colorArray) => {
    const items = Array<any>();
    textArray.forEach((value, index) => {
        items.push({
            text: value,
            color: colorArray[index],
        });
    });
    return items;
};

export const getToggleValue = (ref: { state: { checked: boolean; }, props: any }) => {
    return (ref && ref.state) ?
        ref.state.checked : undefined;
};

export const getRefValue = (ref: { state: { value: any; }, props: any }) => {
    return (ref && ref.state && ref.state.value) ?
        ref.state.value :
        ((ref && ref.props) ? ref.props.selected : undefined)
        || undefined;
};

export const convertToTable = (data: any, accessGraph: any = null) => {
    const result = {
        rows: Array<any>(),
        headers: Array<any>(),
    };

    if (!data || (Object.entries(data).length === 0 && data.constructor === Object)) {
        return result;
    }

    convertDataFromObject(result, data, accessGraph);
    return result;
};

export const convertDataFromObject = (result: { headers: Array<any>, rows: Array<any> }, data: any, accessGraph: any = null) => {
    const currentData = data[0];
    if (!currentData) {
        return result;
    }
    Object.keys(currentData).forEach((key) => {
        if (excludeFromTable.indexOf(key) === -1) {
            const current = currentData[key];
            if (current.display) {
                result.headers.push({ display: current.display || key, slug: key });
            }
        }
    });
    data.forEach((datum: any) => {
        if (datum.client) {
            let clientValue = '';
            if (accessGraph) {
                _.each(accessGraph?.clients, client => {
                    if (client.clientId === datum.client.value || client.name === datum.client.value) {
                        clientValue = client.name;
                    }
                })
            }
            datum.client.value = clientValue;
        }
        result.rows.push(datum);
    });
    return result;
};

export const constructRolesSelect = (value: any) => {
    const { role, text } = value;
    return {
        key: role,
        value: role,
        display: text,
    };
};

export const constructForSelect = (item: any, { key, value, display } : { key?: string, value?: string, display?: string } = {}) => {
    const { _id, id, studyId, name, firstName, surname } = item;
    return {
        key: key ? item[key] : studyId || id,
        value: value ? item[value] : _id || id,
        display: display ? item[display] : name || `${firstName} ${surname}`
    };
};

export const constructForSiteSelect = (item: any, { key, value } : { key?: string, value?: string, display?: string } = {}) => {
    const { _id, id, studyId, name, distanceInMiles } = item;
    return {
        key: key ? item[key] : studyId || id,
        value: value ? item[value] : _id || id,
        display: `${name} (${Math.round(distanceInMiles)} miles)`
    };
};

export const sortArrayOfObjectsAlphabetically = (arr: {}[], propertyName: string) => {
    return arr.sort((a,b) => {
        return a[propertyName].localeCompare(b[propertyName]);
    })
};

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const getValue = (obj: { value: any; }) => {
    return obj ? obj.value : undefined;
};

export const isContainedInUrl = (path: string) => {
    return window.location.pathname.indexOf(path) > -1;
};

export const replaceAll = function (string, search, replacement) {
    return string.replace(new RegExp(search, 'g'), replacement);
};

export const selectOptionNoValue = 'NO__SELECT_VALUE';

export const selectOption = { key: 'select-option', value: selectOptionNoValue, display: 'Select an option' };

export const convertForDropDown = (item: any) => {
    return constructForSelect({
        id: getValue(item.studyId) || getValue(item.id),
        name: item.name
            ? (getValue(item.enabled) === 'Active' ? getValue(item.name) : `(Deactivated) ${getValue(item.name)}`)
            : (`${getValue(item.firstName)} ${`${getValue(item.surname)} `}`),
        enabled: getValue(item.enabled)
    });
}

export const convertToOptions = ({ content, defaultValueIfNoId= '' }) => {
    const options = content.map(item => {
        const itemProp = {
            id: { value: getValue(item.studyId) || item[defaultValueIfNoId] },
            name: { value: getValue(item.name) || item.name },
            enabled: { value: getValue(item.enabled) || item.enabled },
            isSelected: false
        }

        return convertForDropDown(itemProp)
    });

    options.unshift(selectOption);
    return options;
}

export const getEthnicityLabel = (display: string) => {
    switch (display) {
        case 'A':
            return 'British, Mixed British';
        case 'B':
            return 'Irish';
        case 'C':
            return 'Any other White background';
        case 'C2':
            return 'Northern Irish';
        case 'C3':
            return 'Other white, white unspecified';
        case 'CA':
            return 'English';
        case 'CB':
            return 'Scottish';
        case 'CC':
            return 'Welsh';
        case 'CD':
            return 'Cornish';
        case 'CE':
            return 'Cypriot (part not stated)';
        case 'CF':
            return 'Greek';
        case 'CG':
            return 'Greek Cypriot';
        case 'CH':
            return 'Turkish';
        case 'CJ':
            return 'Turkish Cypriot';
        case 'CK':
            return 'Italian';
        case 'CL':
            return 'Irish Traveller';
        case 'CM':
            return 'Traveller';
        case 'CN':
            return 'Gypsy/Romany';
        case 'CP':
            return 'Polish';
        case 'CQ':
            return 'All republics which made up the former USSR';
        case 'CR':
            return 'Kosovan';
        case 'CS':
            return 'Albanian';
        case 'CT':
            return 'Bosnian';
        case 'CU':
            return 'Croatian';
        case 'CV':
            return 'Serbian';
        case 'CW':
            return 'Other republics which made up the former Yugoslavia';
        case 'CX':
            return 'Mixed white';
        case 'CY':
            return 'Other white European, European unspecified, European mixed';
        case 'D':
            return 'White and Black Caribbean';
        case 'E':
            return 'White and Black African';
        case 'F':
            return 'White and Asian';
        case 'G':
            return 'Any other mixed background';
        case 'GA':
            return 'Black and Asian';
        case 'GB':
            return 'Black and Chinese';
        case 'GC':
            return 'Black and White';
        case 'GD':
            return 'Chinese and White';
        case 'GE':
            return 'Asian and Chinese';
        case 'GF':
            return 'Other Mixed, Mixed Unspecified';
        case 'H':
            return 'Indian or British Indian';
        case 'J':
            return 'Pakistani or British Pakistani';
        case 'K':
            return 'Bangladeshi or British Bangladeshi';
        case 'L':
            return 'Any other Asian background';
        case 'LA':
            return 'Mixed Asian';
        case 'LB':
            return 'Punjabi';
        case 'LC':
            return 'Kashmiri';
        case 'LD':
            return 'East African Asian';
        case 'LE':
            return 'Sri Lanka';
        case 'LF':
            return 'Tamil';
        case 'LG':
            return 'Sinhalese';
        case 'LH':
            return 'British Asian';
        case 'LJ':
            return 'Caribbean Asian';
        case 'LK':
            return 'Other Asian, Asian unspecified';
        case 'M':
            return 'Caribbean';
        case 'N':
            return 'African';
        case 'P':
            return 'Any other Black background';
        case 'PA':
            return 'Somali';
        case 'PB':
            return 'Mixed Black';
        case 'PC':
            return 'Nigerian';
        case 'PD':
            return 'Black British';
        case 'PE':
            return 'Other Black, Black unspecified';
        case 'R':
            return 'Chinese';
        case 'S':
            return 'Any other ethnic group';
        case 'SA':
            return 'Vietnamese';
        case 'SB':
            return 'Japanese';
        case 'SC':
            return 'Filipino';
        case 'SD':
            return 'Malaysian';
        case 'SE':
            return 'Any Other Group';
        case 'Z':
            return 'Not stated';
        case '1':
            return 'Hispanic/Latino';
        case '2':
            return 'American Indian/Alaskan Native';
        case '3':
            return 'Asian';
        case '4':
            return 'African-American/Black';
        case '5':
            return 'Native Hawaiian/Other Pacific Islander';
        case '6':
            return 'Caucasian/White';
        case '7':
            return 'Two or more races';
        default:
            return '';
    }
}

export const getUserRole = (): string | null => {
    const token = localStorage.getItem('current-user');

    if (!token) {
        return null;
    }

    const { role }: {role: string} = JSON.parse(token);

    switch (role) {
        case (ROLE_SUPERUSER):
            return ROLE_SUPERUSER;
        case ROLE_ADMIN:
            return ROLE_ADMIN;
        case ROLE_INVESTIGATOR:
            return ROLE_INVESTIGATOR;
        case ROLE_CC_USER:
            return ROLE_CC_USER;
        case ROLE_CLIENT:
            return ROLE_CLIENT;
        case ROLE_SITE_MANAGER:
            return ROLE_SITE_MANAGER;
        case ROLE_TPDP:
            return ROLE_TPDP;
        default:
            return null;
    }
}

const excludeFromTable = ['_id', '__v', 'modified', 'allocations'];
