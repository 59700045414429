import { observable, action } from 'mobx';

export class NotificationStoreBlueprint {
  @observable showNotification: boolean = false;
  @observable type: string = 'userDismiss';
  @observable theme: string = 'failure';
  @observable icon: any;

  @action
  dismissNotification = () => this.showNotification = false

  @action
  updateShowNotification = (value: boolean) => this.showNotification = value;
}

const NotificationStore = new NotificationStoreBlueprint();
export default NotificationStore;
