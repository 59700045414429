import { observable, action } from 'mobx';

import DataHandlerStore from './DataHandlerStore';
import { convertToTable } from '@helpers/util';
import ROUTES from '@constants/routes';
import BaseStore from './BaseStore';
import { callCentreEndpoint } from '@helpers/config';

export interface CallCentreModel {
    id?: string;
    name: string;
}

class CallCentreStore extends BaseStore {
    @observable data: any;
    @observable dataAsTable: any = [];

    @action
    load = async (id: string) => {
        if (id) {
            this.current = {};
            await this.getCallCentre(id);
            return;
        }

        if (this.data && this.data.length > 0) {
            return;
        }
        const response: any = await DataHandlerStore.get(callCentreEndpoint.getCallCentres.name);
        const data = response.data;
        this.data = data;
        this.dataAsTable = convertToTable(data);
    }

    @action
    create = async (body: CallCentreModel) => {
        const response: any = await DataHandlerStore.post(callCentreEndpoint.createCallCentre.name, body);
        this.data = [];
        return Promise.resolve(response);
    }

    @action
    getNew = async () => {
        const { data }: any = await DataHandlerStore.get(callCentreEndpoint.getNewCallCentre.name);
        this.current = data;
    };

    @action
    getCallCentre = async (id: string) => {
        const response: any = await DataHandlerStore.get(callCentreEndpoint.getCallCentre.name, id);
        const data = response.data;
        this.current = data;
        return Promise.resolve(data);
    }

    @action
    navigateEdit = (history: any, id: string) => {
        this.navigate(history, ROUTES.CALLCENTRES.constsructEdit(id));
    }

    @action
    remove = async (id: string) => {
        const response: any = await DataHandlerStore.delete(callCentreEndpoint.deleteCallCentre.name, id);
        this.data = [];
        return Promise.resolve(response);
    }

    @action
    update = async (body: CallCentreModel) => {
        const response: any = await DataHandlerStore.post(callCentreEndpoint.updateCallCentre.name, body);
        this.data = [];
        return Promise.resolve(response);
    }

}

export default new CallCentreStore();
