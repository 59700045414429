import React, { createContext, useReducer, useContext } from 'react';

type Store<T> = [
	({ children }: { children: any; }) => JSX.Element,
	any,
	() => T
]

export default function makeStore<T>(userReducer, initialState): Store<T> {
	const storeContext = createContext(undefined);
	const dispatchContext = createContext(undefined);

	let reducer = userReducer;

	const StoreProvider = ({ children }) => {
		const [store, dispatch] = useReducer(reducer, initialState);

		return (
			<dispatchContext.Provider value={dispatch as unknown as any}>
				<storeContext.Provider value={store}>{children}</storeContext.Provider>
			</dispatchContext.Provider>
		);
	};

	function useStore(): T {
		return useContext(storeContext) as unknown as T;
	}

	function useDispatch() {
		return useContext(dispatchContext);
	}

	return [StoreProvider, useDispatch, useStore];
}
