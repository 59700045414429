import { apiRequest } from '@helpers/request';

export const sendHeartbeat = async function () {
  try {
    return await apiRequest({
      action: 'sendHeartbeat',
      body: {}
    });
  }
  catch (error) {
    return {
      data: {
        success: false,
        error
      }
    };
  }
};
