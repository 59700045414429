import { apiRequest } from '@helpers/request';

export const sendInvalidation = async function (timeout: boolean = false) {
  try {
    return await apiRequest({
      action: 'invalidate',
      body: { timeout: timeout }
    });
  } catch (error) {
    return {
      data: {
        success: false,
        error
      }
    };
  }
};
