import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Navigation from 'components/@shared//navigation';
import Button from 'components/@shared/button';
import Dropdowns from 'components/@shared/dropdowns';
import * as Helper from '@helpers';
import Copy from '@helpers/copy';
import { LoginStoreBlueprint } from '@stores/AuthStore';

import './style.scss';

interface Props {
    history?: any;
    theme?: any;
    considerate?: any;
    AuthStore: LoginStoreBlueprint;
    MenuStore: any;
}

const { Component, Fragment } = React;
@inject('AuthStore', 'MenuStore')

@observer
class Masthead extends Component<Props, {}> {
    mastheadRef: any = React.createRef();

    componentDidMount() {
        const masthead = this.mastheadRef.current;
        const { MenuStore: { load, handleScroll, mastheadHeight } } = this.props;
        load();

        if (masthead) {
            document.body.style.paddingTop = `${mastheadHeight}`;
            window.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', Helper.debounce(this.handleResize, 50));
        }
        else {
          document.body.style.paddingTop = '';
        }
    }

    componentWillUnmount() {
        const { MenuStore: { handleScroll } } = this.props;
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', Helper.debounce(this.handleResize, 50));
    }

    renderDropDown()  {
        const token: any = localStorage.getItem('current-user');
        const userRole = JSON.parse(token);
        const role = userRole && userRole.role

        if (role === 'client' || role === 'cc-user' || role === 'investigator' || role === 'site-manager' ) {
            return <Dropdowns />
        }
        return null
    }

    handleResize = () => {
        const masthead = this.mastheadRef.current;

        if (masthead) {
            document.body.style.paddingTop = window.getComputedStyle(masthead, null).getPropertyValue('height');
        }
        else {
            document.body.style.paddingTop = '';
        }
    };

    handleLogOut = async () => {
        const { history, AuthStore: { logOut } } = this.props;
        await logOut();
        history.push('/login');
    };

    render() {
        const { theme, considerate, MenuStore: { menus, showMasthead, exportAction } } = this.props;
        return (
            <Fragment>
                <header
                    ref={this.mastheadRef}
                    className="masthead"
                    data-theme={theme}
                    data-considerate={considerate}
                    data-active={showMasthead}
                >
                    <a
                        className="company-logo"
                        href={'/'}
                        onClick={(ev) => {
                          ev.preventDefault();

                          // remove item
                          // localStorage.removeItem('item');

                          // go to homepage
                          window.location.assign('/');
                        }}
                        aria-label="Return to the homepage"
                        title="Return to the homepage"
                        data-cy="nav_logo-button"
                    >
                        <img src={`${process.env.PUBLIC_URL}/faze-logo.png`} alt="Havas Lynx Faze" />
                    </a>
                    {menus && menus.length > 0 && <Navigation menus={menus} />}
                    <div className="rightComponents">
                      {this.renderDropDown()}
                      <Button
                          id="logout-button-0"
                          onClick={this.handleLogOut}
                          className="button-logout"
                          type="primary"
                          size="small"
                          theme="weiss"
                          label={Copy.messages.logout.before}
                          dataCy="nav_logout-button"
                      >
                          {Copy.actions.logout}
                      </Button>
                    </div>
                </header>
            </Fragment>
        );
    }
}

export default withRouter(Masthead);
