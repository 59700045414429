import {CANDIDATE_STATUS_EXCLUDED} from '../candidateStatus';

const EnglishUK = {
    pages: {
        login: {
            title: 'Log in',
        },
        resetPassword: {
            title: 'Reset your password',
        },
        dashboard: {
            title: 'Dashboard',
            view: 'View my candidates',
        },
        admins: {
            title: 'All Administrators',
            view: 'View all administrators',
            button: {
                create: {
                    label: 'Add a new admin.',
                    copy: 'Add a new admin',
                },
            },
        },
        users: {
            title: 'All Users',
            view: 'View all users',
            button: {
                create: {
                    label: 'Add a new user.',
                    copy: 'Add a new user',
                },
            },
            import: {
                success: 'The users import was successful'
            }
        },
        'users-edit': {
            title: 'Edit user details',
            view: 'Edit user details',
        },
        users_create: {
            title: 'Create a user',
            view: 'Create a user',
        },
        'users-import-site-staff': {
            title: 'Upload Site Staff Users CSV'
        },
        candidates: {
            title: 'All Candidates',
            view: 'View all candidates',
            button: {
                create: {
                    label: 'Add a new candidate.',
                    copy: 'Add a new candidate',
                },
            },
            import: {
                success: 'The candidate import was successful'
            },
            sendReminder: {
                unsubscribed: 'Sorry, this candidate has unsubscribed from our emails, so no reminder emails can be sent to them.'
            }
        },
        mycandidates: {
            title: 'My Candidates',
            view: 'View my candidates',
            button: {
                create: {
                    label: 'Add a new candidate.',
                    copy: 'Add a new candidate',
                },
            },
        },
        candidates_create: {
            title: 'Add a new candidate',
        },
        candidates_edit: {
            title: 'Edit candidate data',
            hubCandidateOutdated: 'This Candidate is no longer in the Registry, please go to the relevant study to view their profile.',
            exclusionInfoPanelText: 'To change the Candidate status to Excluded, please select a reason for the exclusion and click \'Update status\' before saving',
            externalCandidate: 'This is an external candidate and it cannot be edited.'
        },
        investigators: {
            title: 'All Investigators',
            view: 'View all investigators',
            button: {
                create: {
                    label: 'Add a new investigator.',
                    copy: 'Add a new investigator',
                },
            },
        },
        investigators_create: {
            title: 'Add a new investigator',
        },
        investigators_edit: {
            title: 'Edit investigator data',
        },
        clients: {
            title: 'All Clients',
            view: 'View all clients',
            button: {
                create: {
                    label: 'Add a new client.',
                    copy: 'Add a new client',
                },
            },
        },
        admin: {
            title: 'Administrators',
            view: 'View administrators',
            button: {
                create: {
                    label: 'Add a new admin.',
                    copy: 'Add a new admin',
                },
            },
        },
        'admin-edit': {
            title: 'Edit admin details',
        },
        'admin-create': {
            title: 'Add a new admin',
        },
        'clients-create': {
            title: 'Add a new client',
        },
        clients_edit: {
            title: 'Edit client details',
        },
        sites: {
            title: 'All Sites',
            view: 'View all sites',
            button: {
                create: {
                    label: 'Add a new site.',
                    copy: 'Add a new site',
                },
            },
            import: {
                success: 'The sites import was successful'
            },
            titles: {
                edit: 'Edit site details',
                create: 'Add a new site',
            }
        },
        'sites-create': {
            title: 'Add a new site',
        },
        'sites-edit': {
            title: 'Edit site details',
        },
        'sites-import': {
            title: 'Upload Sites CSV'
        },
        mySites: {
            title: 'My Sites',
            view: 'View my sites',
            button: {
                create: {
                    label: 'Add a new site.',
                    copy: 'Add a new site',
                },
            },
        },
        studies: {
            title: 'All Studies',
            view: 'View all studies',
            button: {
                create: {
                    label: 'Add a new study.',
                    copy: 'Add a new study',
                },
            },
        },
        dataExport: {
            title: 'Data Export',
            view: 'View all export'
        },
        dataExtracts: {
            title: 'Data Extracts',
            view: 'View all extracts',
            notesTitle: 'IMPORTANT NOTES:',
            notes: ['Admin users can download the pre-set data extracts by date range below', 'Any person handling data should have completed the GDPR, GCP and HIPAA training; please reach out to the project management (PM) team if you have not', 'All data should be handled in a secure manner via the Y-drive server, with nothing being shared with teammates on email, Teams or OneDrive, nor saved to a desktop for an extended period of time', 'The Y-drive has restricted access intentionally; if someone needs access to the data extracts, please reach out to the PM team', 'Not all of the data extracts can be shared with the clients or third parties; please check with the project team before sharing to check', 'It’s recommended that you ask IT to install 7zip rather than using Windows’ default tool as it doesn’t always zip/unzip files correctly'],
            selectClientDropdown: 'Select client',
            selectExtractDropdown: 'Select extract',
            selectExtractDropdownPlaceholder: 'Select an option',
            extractsTitle: 'Extracts within the zip file and what data they contain',
            extractsSubtitle: 'Extracts',
            extractsCopyLink: 'Please see the <a href="https://havasfaze.atlassian.net/wiki/spaces/0S/pages/324599812/Data+Extracts+available+for+F1+Admin+Users" target="_blank" rel="noreferrer">Data Extracts list</a> to see what the full list of extracts in the zip file are.',
            extractsNames: ['AZ COVID Trial & You - Supernova', 'AZ COPD Trial & You <span>(this is the Registry)</span>', 'AZ COPD Trial & You - Crescendo', 'AZ COPD Trial & You - Future Trials', 'AZ COPD Trial & You - Breztri NGP', 'AZ COPD Trial & You - Titania', 'AZ Oberon', 'AZ Oberon Titania', 'AZ Oberon Titania - Future Trials', 'AZ Oberon - Future Trials', 'AZ Titania', 'AZ Titania - Future Trials'],
            dataIncludedTitle: 'Data included',
            dataIncludedCopy: 'All available data fields in the database should be included in the extract. For details on what is included, please see the <a href="https://havasfaze.atlassian.net/l/cp/8jWDrxYF" target="_blank" rel="noreferrer">Data Dictionary</a>. If you don’t have access to this link, please submit a  <a href="https://havasfaze.atlassian.net/jira/core/projects/PP/form/3" target="_blank" rel="noreferrer">JIRASupport ticket.</a>',
            fromDate: 'From the start of:',
            toDate: 'Until:',
            toDateNote: 'Please note: ‘until’ is not inclusive. If you need from 00:00 on 24th March to 23:59 on 31st March, please select 24/03/23 - 01/04/23',
            allDates: 'All data available',
            exportButton: 'Export',
            selectExtract: 'Select extract',
            exportErrorMessage: 'Unfortunately, there\'s been an unexpected error with the extract you requested. Please try again or submit a JIRA support ticket.'
        },
        support: {
            title: 'Need support?',
            view: 'Need support?'
        },
        performanceDashboard: {
            title: 'Performance Dashboard',
            view: 'Performance Dashboard',
            error: 'Sorry, Performance Dashboard has not been set up for this study.',
        },
        splashScreen: {
            title: 'Please select a study',
            view: 'View all export'
        },
        'studies-edit': {
            title: 'Edit study details',
        },
        'studies-create': {
            title: 'Add a new study',
        },
        'call-centres': {
            title: 'All Call Centres',
            view: 'View all call centres',
            button: {
                create: {
                    label: 'Add a new call centre.',
                    copy: 'Add a new call centre',
                },
            },
        },
        'call-centre-edit': {
            title: 'Edit Call Centre',
            view: 'Edit call centre',
        },
        'call-centre-create': {
            title: 'Create New Call Centre',
            view: 'Create new call centre',
        },
        'my-profile': {
            title: 'My Profile',
            view: 'View my profile',
        },
        fourohfour: {
            title: 'Oops! This page doesn\'t exist.',
            copy: 'You seem to have found a page that doesn\'t exist. Try using the menu to navigate around.',
        },
        csvUpload: {
            title: 'CSV upload',
            view: 'Upload your csv here',
        },
    },
    actions: {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        submit: 'Submit',
        save: 'Save',
        send: 'Send',
        saveNote: 'Save Note',
        cancel: 'Cancel',
        close: 'Close',
        reset: 'Reset',
        positive: 'Yes',
        confirm: 'Confirm',
        negative: 'No',
        login: 'Log in',
        logout: 'Log out',
        resetPassword: 'Reset password',
    },
    statuses: {
        'new-candidate': 'New candidate',
        registered: 'Registered',
        preScreened: 'Pre-screened',
        bookedScreening: 'Booked screening',
        consented: 'Consented',
        enrolled: 'Enrolled',
        excluded: CANDIDATE_STATUS_EXCLUDED,
    },
    headers: {
        age: 'Age',
        campaignContent: 'Campaign content',
        campaignMedium: 'Campaign medium',
        campaignName: 'Campaign name',
        campaignSource: 'Campaign source',
        campaignTerm: 'Campaign term',
        condition: 'Days since status updated',
        contactNumber: 'Phone Number',
        created: 'Submission date/time',
        'date-applied': 'Date Applied',
        dob: 'Date of birth',
        email: 'Email address',
        firstName: 'First name',
        lasttName: 'Last name',
        formQuestionResponses: 'Questions',
        id: 'Candidate ID',
        ivrsCode: 'IVRS code',
        notes: 'Notes',
        screeningQuestionResponses: 'Screening questions',
        screenVisitDate: 'Screen visit date',
        gender: 'Sex',
        role: 'Role',
        enabled: 'Enabled',
        status: 'Status',
        priorityStatus: 'Priority',
        country: 'Country',
        siteIdentifier: 'Site ID',
        siteRegion: 'Region',
        utmSource: 'UTM Source',
        utmMedium: 'UTM Medium',
        utmFormat: 'UTM Format',
        lastUpdated: 'Last Updated',
        studySiteId: 'Preferred site',
        surname: 'Surname',
        utmData: 'UTM data',
        websiteUrl: 'Website',
        validated: 'Validated',
        rejected: 'Rejected',
        dropped: 'Dropped',
    },
    summaryBlocks: {
        newReferrals: {
            name: 'newReferrals',
            title: 'New Referrals',
            description: 'Count of records with the status ‘Registered',
            theme: 'success',
        },
        overdueReferrals: {
            name: 'overdueReferrals',
            title: 'Overdue Referrals',
            description: 'Count of candidates who have status ‘Registered’ for >2 days*',
            theme: 'failure',
        },
        lateScreenings: {
            name: 'lateScreenings',
            title: 'Late Screenings',
            description: 'Count of candidates who have status ‘Screening booked’ beyond their screening date*',
            theme: 'none',
        },
        consentUpdates: {
            name: 'consentUpdates',
            title: 'Consent Updates',
            description: 'Count of candidates who have status ‘Consented',
            theme: 'none',
        },
        randomisationUpdates: {
            name: 'randomisationUpdates',
            title: 'Randomisation updates',
            description: 'Count of candidates who have status ‘Enrolled',
            theme: 'none',
        },
    },
    formFields: {
        contactNumber: {
            description: 'We need this to contact you',
            placeholder: '0161 225 1234',
        },
        county: {
            description: '',
            placeholder: 'La France',
        },
        created: {
            description: '',
            placeholder: '',
        },
        clientForm: {
            name: {
                description: 'Client Name',
                placeholder: 'Enter client Name',
            },
            contactName: {
                description: 'Contact Name',
                placeholder: 'Enter contact name',
            },
            contactEmail: {
                description: 'Contact Email',
                placeholder: 'Enter contact email',
            },
        },
        dateApplied: {
            description: '',
            placeholder: '',
        },
        dob: {
            description: 'We need this to place you in a relevant study',
            placeholder: 'Jan 20th 1979',
        },
        email: {
            description: 'We need this to contact you',
            placeholder: 'firstname.lastname@domain.tld',
        },
        firstName: {
            description: 'We need this to help identify you',
            placeholder: 'John',
        },
        lastName: {
            description: 'We need this to help identify you',
            placeholder: 'John',
        },
        formQuestionResponses: {
            description: '',
            placeholder: '',
        },
        id: {
            description: 'We need this to help identify you',
            placeholder: '',
        },
        ivrsCode: {
            description: '',
            placeholder: '1-234-56',
        },
        notes: {
            description: '',
            display: 'Add new note',
            disclaimer: 'Do not include any referral <strong>Personal Identifiable Information (PII)</strong> on notes',
            placeholder: 'Enter new note',
        },
        postcode: {
            description: 'May be used to assist delivery',
            placeholder: 'M1 7EP',
        },
        studySiteId: {
            description: '',
            placeholder: '',
        },
        region: {
            description: 'May be used to assist delivery',
            placeholder: 'UK',
        },
        screeningQuestionResponses: {
            description: '',
            placeholder: '',
        },
        screenVisitDate: {
            description: '',
            placeholder: '',
        },
        gender: {
            description: 'We need this to place you in a relevant study',
            placeholder: '',
            options: {
                male: 'Male',
                female: 'Female',
                notcollected: 'Not Collected',
                pnts: 'Prefer Not To Say',
            },
        },
        status: {
            description: '',
            placeholder: '',
        },
        streetAddressFirst: {
            description: 'Street and number, PO Box, c/o',
            placeholder: '54 Princess Street',
        },
        streetAddressSecond: {
            description: 'Flat, suite, unit, building, floor, etc',
            placeholder: 'Lx Labs, First Floor',
        },
        study: {
            description: '',
            placeholder: '',
        },
        studyForm: {
            client: {
                description: 'Client',
            },
            name: {
                description: 'Study Name',
                placeholder: 'Enter Study Name',
            },
            description: {
                description: 'Study Description',
                placeholder: 'Enter Study Description',
            },
            slug: {
                description: 'Slug',
                placeholder: 'Enter Slug',
            },
            url: {
                description: 'Recruitment site URL',
                placeholder: 'Enter Recruitment site URL',
            },
        },
        siteForm: {},
        surname: {
            description: 'We need this to help identify you',
            placeholder: 'Smith',
        },
        townCity: {
            description: '',
            placeholder: 'Manchester',
        },
        utmData: {
            description: '',
            display: 'thomasxbanks',
            placeholder: '',
        },
    },
    messages: {
        resetPassword: '',
        generic_error: 'Something has gone very, very wrong!',
        required: 'Required',
        zero_records: 'There are no results.',
        missing_data: 'TBC',
        login: {
            before: 'Log in to your account.',
            during: 'Logging in. Please wait.',
        },
        logout: {
            before: 'Log out of your account',
            confirm: 'Are you sure you want to log out? There is no undo on this option - you will need to log back in.',
            yes: 'Yes, log out',
            no: 'No, don\'t log out',
        },
        password: {
            reset: 'Reset password',
            success: 'Password Reset email sent',
            failure: 'There has been an error sending your Password Reset email. Please try again',
            notFound: 'User does not exist',
        },
        welcome: 'Welcome back!',
        view_profile: 'View full profile',
        flaring_reminder: 'Flaring Reminder Email',
        save: {
            success: 'Saved',
            failure: 'Not saved',
        },
        sendEmail: {
            areSure: 'Are you sure you want to send an email to this candidate.',
            prevContact: 'Days since last contact:',
        },
        loading: 'Loading',
    },

};

export default EnglishUK;
