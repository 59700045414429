import { action, observable } from 'mobx';
import jwtDecode from 'jwt-decode';

import { getMenu } from '@constants/menu';
import BaseStore from './BaseStore';
import { ROLE_ADMIN, ROLE_CLIENT, ROLE_INVESTIGATOR, ROLE_SITE_MANAGER } from '@helpers/RoleTypes';

const menuCachedKey = 'menu';

export class MenuStoreBlueprint extends BaseStore {
    @observable menus: any = [];

    @action
    load = () => {
        const menu = localStorage.getItem(menuCachedKey);
        if (menu) {
            this.menus = JSON.parse(menu);
            return;
        }
        const token = localStorage.getItem('token');

        if (!token) {
            return;
        }
        const currentUser: any = jwtDecode(token);
        // if (!currentUser) {
        //     return;
        // }

        if (currentUser.role === 'administrator') {
            this.getExtracts();
        }

        this.menus = getMenu(currentUser.role);
        if (this.menus) {
            localStorage.setItem('current-user', JSON.stringify(currentUser));
            this.addPerformanceDashboardMenu(this.menus);
            this.addSupportMenu(this.menus);
            this.addDataExtractMenu(this.menus);
            localStorage.setItem(menuCachedKey, JSON.stringify(this.menus));
        }
    }

    addDataExtractMenu = (navItems) => {
        const token: any = localStorage.getItem('current-user');
        if (!navItems || !token) {
            return;
        }

        const { role } = JSON.parse(token);
        if (role === ROLE_ADMIN) {
            navItems.push({url: '/data-extracts', copy: 'Data Extracts', label: 'Data Extracts', icon: 'candidates'});
        }
    }

    addSupportMenu = (navItems) => {
        const token: any = localStorage.getItem('current-user');
        if (!navItems || !token) {
            return;
        }

        const { role } = JSON.parse(token);
        if (role === ROLE_INVESTIGATOR) {
            navItems.push({ url: '/user-support', copy: 'User Support', label: 'User Support', icon: 'phone' });
        }
    }

    addPerformanceDashboardMenu = (navItems) => {
        const token: any = localStorage.getItem('current-user');

        if (!navItems || !token) {
            return;
        }

        const { role } = JSON.parse(token);

        if (role === ROLE_CLIENT) {
            // navItems.push({ url: '/performance-dashboard', copy: 'Performance Dashboard', label: 'Performance Dashboard', icon: 'study' });
        }
    }

}

const MenuStore = new MenuStoreBlueprint();
export default MenuStore;
