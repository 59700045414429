import { observable, action } from 'mobx';

export class TabStoreBlueprint {
  @observable tabToShow: number = 0;
  @observable tabName: string = '';

  @action
  updateActiveTab = (index: number) => this.tabToShow = index;

  @action
  updateActiveTabName = (name: string) => this.tabName = name;
}

const TabStore = new TabStoreBlueprint();
export default TabStore;
