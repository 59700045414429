import { apiRequest } from '@helpers/request';

export const siteImportXlsx = async function ({ body }) {
  try {
    return await apiRequest({
      action: 'uploadSitesFile',
      body,
    });
  }
  catch (error) {
    return {
      data: {
        success: false,
        error
      }
    };
  }
};

export const userImportXlsx = async function ({ body }) {
  try {
    return await apiRequest({
      action: 'uploadUsersFile',
      body,
    });
  }
  catch (error) {
    return {
      data: {
        success: false,
        error
      }
    };
  }
};

export const candidateImportXlsx = async function ({ body }) {
  try {
    return await apiRequest({
      action: 'uploadCandidatesFile',
      body,
    });
  }
  catch (error) {
    return {
      data: {
        success: false,
        error
      }
    };
  }
};
