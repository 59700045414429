import React, { createContext, useReducer } from 'react';
import { initialState as appState } from './state';
import { reducer as appReducer } from './reducer';

const initialState = {
  ...appState,
};

const reducer = (state, action) => {
  state = appReducer(state, action);

  return state;
};

export const AppContext = createContext();

export const AppState = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>
    {children}
  </AppContext.Provider>
};
