import { observable, action } from 'mobx';
import DataHandlerStore from './DataHandlerStore';
import { entitiesEndpoint } from '@helpers/config';

export interface EntityModel {
  entityId: string;
  entityType: string;
  permissionType: string;
  relatedId: string;
}

const { post } = DataHandlerStore;

export class EntityStoreBluePrint {
  @observable data: any;

  @action
  addPermissions = async (body: EntityModel) => {
    const response: any = await post(entitiesEndpoint.addPermissions.name, body);
    this.data = response;
    return Promise.resolve(response);
  }

  @action
  addUserToEntity = async (body: EntityModel) => {
    const response: any = await post(entitiesEndpoint.addUserToEntity.name, body);
    this.data = response;
    return Promise.resolve(response);
  }

  @action
  removeUserFromEntity = async (body: EntityModel) => {
    const response: any = await post(entitiesEndpoint.removeUserFromEntity.name, body);
    return Promise.resolve(response);
  }

  @action
  removePermissions = async (body: EntityModel) => {
    const response: any = await post(entitiesEndpoint.removePermissions.name, body);
    return Promise.resolve(response);
  }

  @action
  remove = async (id: string) => {
    const response: any = await DataHandlerStore.delete(entitiesEndpoint.deleteEntity.name, id);
    return Promise.resolve(response);
  }
}

const EntityStore = new EntityStoreBluePrint();
export default EntityStore;
