import { action, computed, observable } from 'mobx';
import {
    constructRolesSelect,
    convertToTable,
    getValue,
} from '@helpers/util';
import ROUTES from '@constants/routes';
import DataHandlerStore from './DataHandlerStore';
import BaseStore from './BaseStore';
import SitesStore from './SitesStore';
import StudyStore from './StudyStore';
import { ROLE_CC_USER } from '@helpers/RoleTypes';
import { listEndpoint, userEndpoint } from '@helpers/config';
import DataTableStore from './DataTableStore';
import { getAccessGraph } from 'api/accessGraph';

export interface UserModel {
    id?: string;
    firstName: string;
    surname: string;
    company: string;
    phone: string;
    countryCode: string;
    email: string;
    role: string;
    enabled: boolean;
}

export class UserStoreBlueprint extends BaseStore {
    @observable user = { headers: [], rows: [] };
    @observable _roles: any = [];
    @observable data: any = [];
    @observable baseUserData: any = [];
    @observable dataAsTable: any = [];
    @observable roleList: any = [];
    @observable splash: any = {};
    @observable showMonthlyEmailPreferences: boolean = false;
    @observable showWeeklyEmailPreferences: boolean = false;
    @observable showNoEmailPreferences: boolean = false;
    @observable filterObject: any = {};
    @observable currentRole: string = '';
    @observable accessGraph: any = null;

    @computed
    get canViewCallCentreDropdown() {
        return this.isFormCreation && (this.current.role.value === ROLE_CC_USER) && this.current.clientId && this.current.clientId.value;
    }

    @computed
    get roles() {
        return this._roles
            .map((row: { role: string, text: string, urlId: string }) => constructRolesSelect(row))
            .filter((item) => {
                if (this.isSuperUser) {
                    return item;
                }
                if (this.isAdmin && item.value !== 'superuser') {
                    return item;
                }
            });
    }

    @computed
    get userRoles() {
        return this._roles
            .map((row: { role: string, text: string, urlId: string }) => {
                return ({ value: row.role, display: row.text, url: `${ROUTES.USERS.index}/${row.urlId}` });
            }).filter((item) => {
                if (this.isAdmin && item.value === 'superuser') {
                    return;
                }
                return item;
            });
    }

    @action
    resetFilters = async () => {
        DataTableStore.resetSelectedPage();
        DataTableStore.resetSelectedFilter();
        this.filterUserResults(this.currentRole);
        DataTableStore.data = this.dataAsTable;
    }

    @action
    filterBy = async (type: any, filterValue: string | undefined) => {
        DataTableStore.resetSelectedPage();
        if (!type || !filterValue) {
            await this.resetFilters()
            return
        }
        this.filterObject[type] = filterValue;

        if (Object.keys(filterValue).length !== 0) {
            await this.applyFilterEnabled(this.filterObject);
        }
        DataTableStore.data = this.dataAsTable;
    }

    @action
    applyFilterEnabled = async (filter: any) => {
        const filterKeys: Array<string> = Object.keys(filter);
            if (filterKeys.includes('enabled')) {
                this.filterUserResults(this.currentRole);
                switch (filter.enabled) {
                    case 'Active':
                        this.data = this.data.filter(obj => {
                            return obj.enabled.value === 'Active'
                        })
                        this.dataAsTable = convertToTable(this.data, this.accessGraph)
                        return;
                    case 'Deactivated':
                        this.data = this.data.filter(obj => {
                            return obj.enabled.value === 'Deactivated'
                        })
                        this.dataAsTable = convertToTable(this.data, this.accessGraph)
                        return;
                    default:
                        break;
                }
            }
    }

    @action
    updateCallCentreId = (selectedId) => {
        this.current.callCentreId = selectedId;
    }

    @action
    load = async (id: string | undefined, filter: string = '') => {
        this.accessGraph = await getAccessGraph(true, true);
        await this.getRoles();
        this.currentRole = filter;
        if (!id) {
            await this.getUsers(filter);
            await this.filterBy(undefined, undefined)
            return;
        }
        if (id === 'new') {
            SitesStore.resetOnSelect();
            StudyStore.resetOnSelect();
            this.currentAccess = {};
            this.siteAccess = [];
            const response: any = await DataHandlerStore.get(userEndpoint.getNewUser.name, filter);
            const { data } = response;
            this.current = data;
            return;
        }
        await this.getUser(id);
    };

    @action
    getUser = async (id: string) => {
        if (id === 'new') {
            return Promise.resolve(this.current);
        }
        this.siteAccess = [];
        const response: any = await DataHandlerStore.get(userEndpoint.getUserById.name, id);
        const { data } = response;
        this.current = data;
        return Promise.resolve(data);
    };

    @action
    getUsers = async (filter: string) => {
        const response: any = await DataHandlerStore.get(userEndpoint.getUsers.name);
        this.baseUserData = [ ...response.data ];
        const filterBy = response.data.filter(datum => getValue(datum.role) === filter);
        this.data = filterBy;
        this.dataAsTable = convertToTable(filterBy, this.accessGraph);
    };

    @action
    create = async (user: UserModel) => {
        const newUser = await DataHandlerStore.post(userEndpoint.createUser.name, user);

        this.data = [];
        return Promise.resolve(newUser);
    };

    @action
    filter = async (role: string) => {
        const result = this.data.filter((x: { role: string; }) => x.role === role);
        this.data = result;
        this.dataAsTable = convertToTable(result, this.accessGraph);
    };

    @action
    filterUserResults = (role: string) => {
        this.currentRole = role;
        let filterBy = { ...this.baseUserData };
        filterBy = this.baseUserData.filter(data => data.role.value === role);
        this.data = filterBy;
        this.dataAsTable = convertToTable(filterBy, this.accessGraph)
    }

    @action
    navigateEdit = (history: any, url: string) => {
        this.navigate(history, url);
        SitesStore.resetOnSelect();
        StudyStore.resetOnSelect();
    };

    @action
    update = async (body: UserModel) => {
        const response: any = await DataHandlerStore.post(userEndpoint.updateUser.name, body);
        this.current = response.data;
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    updateRows = (headers, rows) => {
        this.user.headers = headers;
        this.user.rows = rows;
    };

    @action
    resetSiteAccess = () => {
        this.siteAccess = [];
        StudyStore.populatedStudies = [];
    }

    @action
    remove = async (id: string) => {
        await DataHandlerStore.delete(userEndpoint.deleteUser.name, id);
    };

    @action
    getUserSplash = async () => {
        const response: any = await DataHandlerStore.get(userEndpoint.userSplash.name);
        this.splash = response.data;
    }

    @action
    updateEmailPreference = (value: string) => {
        this.resetEmailPreferences();
        switch (value) {
            case '0': {
                this.showWeeklyEmailPreferences = true;
                break;
            }
            case '1': {

                this.showMonthlyEmailPreferences = true;
                break;
            }
            default:
                this.showNoEmailPreferences = true;
                break;
        }
    }

    @action
    updateSelectedClientId = (id) => {
        UserStore.current.clientId = id;
    }

    // Private Methods
    getRoles = async () => {
        const response: any = await DataHandlerStore.get(listEndpoint.getRoles.name);
        this._roles = response.data;
    };

    resetEmailPreferences = () => {
        this.showMonthlyEmailPreferences = false;
        this.showWeeklyEmailPreferences = false;
        this.showNoEmailPreferences = false;
    }
}

const UserStore = new UserStoreBlueprint();
export default UserStore;
