import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { AppState } from 'reducer/app';
import { AccessGraphProvider } from 'reducer/accessGraph';
import * as serviceWorker from './serviceWorker';
import stores from '@stores';

import './index.scss';

const rootEl = document.getElementById('root');
/* eslint-disable-next-line */
ReactDOM.render(
  <AppState>
    <AccessGraphProvider>
      <Provider {...stores}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </AccessGraphProvider>
  </AppState>, rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
