import { action, computed, observable } from 'mobx';
import downloadjs from 'downloadjs';
import DataHandlerStore from './DataHandlerStore';
import BaseStore from './BaseStore';
import DataTableStore from './DataTableStore';
import { constructForSelect, convertToTable, getValue, sanitiseData } from '@helpers/util';
import ROUTES from '@constants/routes';
import {
    CandidateModel,
    CandidateModelCreate,
    CandidateNote, CandidateNoteResponse,
    CommunicationSendRequest,
    ParticipantStatus,
    CandidateTab, StudyTab
} from './@StoreModels';
import { candidateEndpoint } from '@helpers/config';
import { ICandidateLoadRequest } from './ICandidateLoadRequest';

const FILTER_OPTION_ALL: string = 'all';

export class CandidateStore extends BaseStore {
    @observable data: any = [];
    @observable filterStatuses: any = [];
    @observable filterPriorities: any = [];
    @observable filterValueData: any = [];
    @observable dataAsTable: any = [];
    @observable loading: any = [];
    @observable error: any = {};
    @observable kpis: any = [];
    @observable screenVisitDateSelected: boolean = false;
    @observable exportData: any = [];
    @observable filteredStudies: any = [];
    @observable dataExport: any = { client: null, studies: null, date: null };
    @observable dataExtract: any = { reportId: null, fromDate: null, toDate: null };
    @observable dataExtractIsComplete: boolean = false;
    @observable dataExportIsComplete: boolean = false;
    @observable dataIneligibleExportIsComplete: boolean = false;
    @observable selectedStudyId: string = '';
    @observable candidateFilters: any;
    @observable candidateTabs: CandidateTab[];
    @observable studyTabs: StudyTab[];
    @observable isLoading: boolean = false;

    filterArray: any = [];
    filterObject: any = {};
    currentTab: any = null;

    @computed
    get FilterPriority() {
        return this.filterPriorities.map((item) => {
            return constructForSelect({
                id: item.code,
                name: item.text
            })
        });
    }

    @computed
    get FilterStatus() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.status)))];

        return unique.map((val) => {
            const statusArr = JSON.parse(JSON.stringify(this.statuses));
            return constructForSelect({
                id: val,
                name: statusArr?.find((status) => status?.code === val)?.text || val
            });
        });
    }

    @computed
    get FilterCountry() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.i18nCountry)))];
        return unique.map((val) => {
            return constructForSelect({
                id: val,
                name: this.countries.find((country) => getValue(country) === val).display || val
            })
        })
    }

    @computed
    get FilterRegion() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.addressRegion)))];
        return unique.map((val) => {
            return constructForSelect({
                id: val,
                name: val
            })
        })
    }

    @computed
    get FilterSiteIdentifier() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.siteIdentifier)))];
        return unique.map((val) => {
            return constructForSelect({
                id: val,
                name: val
            })
        })
    }

    @computed
    get FilterUTMSource() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.utmSource)))];
        return unique.map((val) => {
            if (val) {
                return constructForSelect({
                    id: val,
                    name: val
                })
            }
        })
    }

    @computed
    get FilterUTMMedium() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.utmMedium)))];
        return unique.map((val) => {
            if (val) {
                return constructForSelect({
                    id: val,
                    name: val
                })
            }
        })
    }

    @computed
    get FilterUTMFormat() {
        const unique = [...new Set(this.filterValueData.map(obj => getValue(obj.utmFormat)))];
        return unique.map((val) => {
            if (val) {
                return constructForSelect({
                    id: val,
                    name: val
                })
            }
        })
    }

    // Ajax calls
    @action
    addNote = async (body: CandidateNote) => {
        const addNoteResponse: any = await DataHandlerStore.post(candidateEndpoint.addNote.name, body);

        if (addNoteResponse && addNoteResponse.created) {
            const notes = await this.getNotes(body.id) as CandidateNoteResponse;

            if (this.current) {
                this.current.notes = notes.data;
            } else {
                this.current.rows[0].notes = notes.data;
            }
        }

        return Promise.resolve(addNoteResponse);
    };

    @action
    getNotes = async (id: string) => {
        const response = await DataHandlerStore.get(candidateEndpoint.getNotes.name, id);
        return Promise.resolve(response);
    };

    @action
    create = async (body: CandidateModel) => {
        const response: any = await DataHandlerStore.post(candidateEndpoint.createParticipant.name, body);
        const { data } = response;
        this.current = {};
        this.current = sanitiseData(data);
        this.data = [];
        return Promise.resolve(data);
    };

    @action
    deleteParticipant = async (body: CandidateModel) => {
        const response = await DataHandlerStore.post(candidateEndpoint.deleteParticipant.name, body);
        return Promise.resolve(response);
    };

    @action
    filterBy = async (type, filterValue: string) => {
        DataTableStore.resetSelectedPage();

        switch (type) {
            case 'date-applied':
                this.filterObject['date-applied'] = filterValue === 'asc' ? 'asc' : 'desc';
                break;
            case 'priorityStatus':
                this.filterObject['priorityStatus'] = filterValue;
                break;
            case 'status':
                this.filterObject['status'] = filterValue;
                break;
            default:
                this.filterObject[type] = filterValue;
        }

        const request: ICandidateLoadRequest = {
            id: '',
            filter: this.filterObject,
            currentTabArg: this.currentTab
        }

        await this.load(request);
        this.dataAsTable.rows = this.data;
    };

    @action
    remove = async (id: string) => {
        const response: any = await DataHandlerStore.delete(candidateEndpoint.deleteCandidate.name, id);
        return Promise.resolve(response);
    };

    @action
    load = async (request: ICandidateLoadRequest) => {
        if (!this.isLoading) {
            this.isLoading = true;
            await this.getCountries();
            await this.getEthnicities();
            await this.getContactOptions();

            this.resetExcludedFlags();

            const itemValue = localStorage.getItem('item');

            if (itemValue) {
                let item: any = JSON.parse(itemValue);
                this.filterStatuses = await this.getStatuses(item.studyId);
                this.filterPriorities = await this.getPriorities(item.studyId);
                await this.getExclusionReasons(item.studyId);

                if (request.id) {
                    this.current = {};
                    await this.getParticipant(request.id);

                    this.screenVisitDateSelected = getValue(this.current.status) === 'screen-visit-booked';
                } else {
                    let data;

                    const studyTabs: StudyTab[] = await this.getDashboardTabs(item.studyId, {
                        status: FILTER_OPTION_ALL,
                        currentTab: FILTER_OPTION_ALL,
                        ...request.filter
                    });

                    const firstStudyTabCode = studyTabs[0].code;
                    const currentTab = request['selectedTab'] ? request['selectedTab'] : ( sessionStorage.getItem('lastSelectedTab') ?  sessionStorage.getItem('lastSelectedTab') : firstStudyTabCode);


                        sessionStorage.setItem('lastSelectedTab', currentTab);


                    if (request.filter && request.filter['status'] && request.filter['status'] !== 'undefined') {
                        data = await this.getCandidateDashboard(item.studyId, {
                            status: request.filter['status'],
                            currentTab: firstStudyTabCode,
                            ...request.filter
                        })
                    } else {
                        data = await this.getCandidateDashboard(item.studyId, {
                            currentTab: currentTab,
                            ...request.filter
                        });
                    }

                    this.currentTab = request.filter;

                    this.data = data;
                    this.dataAsTable = convertToTable(data);
                    this.filterValueData = data; // this is used by the filters

                    if (data) {
                        if (request.filter && request.filter['date-applied'] === 'asc') {
                            this.data = this.dataAsTable.rows.slice().sort((a, b) => {
                                const prev: any = new Date(getValue(b.created));
                                const curr: any = new Date(getValue(a.created));
                                return curr - prev;
                            });
                        } else {
                            this.data = this.dataAsTable.rows.slice().sort((a, b) => {
                                const prev: any = new Date(getValue(b.created));
                                const curr: any = new Date(getValue(a.created));
                                return prev - curr;
                            });
                        }
                    }
                }
            }

            this.isLoading = false;
        }
    };

    @action
    navigateEdit = (history: any, id: string) => {
        this.navigate(history, ROUTES.CANDIDADTES.constsructEdit(id));
    };

    @action
    updateParticipantStatus = async (status: ParticipantStatus) => {
        const response = await DataHandlerStore.post(candidateEndpoint.updateParticipantStatus.name, status);
        return Promise.resolve(response);
    };

    @action
    updateParticipant = async (body: CandidateModel) => {
        const response = await DataHandlerStore.post(candidateEndpoint.updateParticipant.name, body);
        return Promise.resolve(response);
    };

    @action
    createParticipant = async (body: CandidateModelCreate) => {
        const response = await DataHandlerStore.post(candidateEndpoint.createParticipant.name, body);
        return Promise.resolve(response);
    };

    @action
    getDashboardTabs = async (studyId: string, overrides = {}) => {
        try {
            const body = {
                studyId: studyId,
                country: FILTER_OPTION_ALL,
                siteIdentifier: FILTER_OPTION_ALL,
                siteRegion: FILTER_OPTION_ALL,
                status: FILTER_OPTION_ALL,
                utmSource: FILTER_OPTION_ALL,
                utmMedium: FILTER_OPTION_ALL,
                utmFormat: FILTER_OPTION_ALL,
                priorityStatus: FILTER_OPTION_ALL,
                ...overrides
            };

            const response: any = await DataHandlerStore.post(candidateEndpoint.getDashboardTabsForStudyId.name, body);
            this.studyTabs = response;

            return Promise.resolve(response);
        } catch {
            return [];
        }
    };
    @action
    getCandidateDashboard = async (studyId: string, overrides = {}) => {
        try {
            const body = {
                studyId: studyId,
                country: FILTER_OPTION_ALL,
                siteIdentifier: FILTER_OPTION_ALL,
                siteRegion: FILTER_OPTION_ALL,
                status: FILTER_OPTION_ALL,
                utmSource: FILTER_OPTION_ALL,
                utmMedium: FILTER_OPTION_ALL,
                utmFormat: FILTER_OPTION_ALL,
                priorityStatus: FILTER_OPTION_ALL,
                ...overrides
            };

            const summaryResponse: any = await this.getCandidatesDashboardSummary(body);
            this.kpis = summaryResponse.summaryData;

            const response: any = await DataHandlerStore.post(candidateEndpoint.getCandidatesDashboardForStudyId.name, body);
            const { candidateData, candidateFilters, tabs } = response;
            this.candidateFilters = candidateFilters;
            this.candidateTabs = tabs;

            return Promise.resolve(candidateData);
        } catch {
            return [];
        }
    };

    @action
    getCandidatesDashboardSummary = async (body) => {
        const response = await DataHandlerStore.post(candidateEndpoint.getCandidatesDashboardSummary.name, body)
        return Promise.resolve(response);
    }

    @action
    getCommunicationsById = async (id: string) => {
        const response = await DataHandlerStore.get(candidateEndpoint.getCommunicationsById.name, id);
        return Promise.resolve(response);
    }

    @action
    getManualAvailableMessages = async (id: string) => {
        const response = await DataHandlerStore.post(candidateEndpoint.getManualAvailableMessages.name, { id: id });
        return Promise.resolve(response);
    }

    @action
    sendEmailManual = async (id: string, type: string) => {
        const response = await DataHandlerStore.post(candidateEndpoint.sendEmailManual.name, {
            id: id,
            messageType: type,
            service: 'nodemailer'
        });
        return Promise.resolve(response);
    }

    @action
    sendCommunication = async (body: CommunicationSendRequest) => {
        const response = await DataHandlerStore.post(candidateEndpoint.sendCommunication.name, body);
        return Promise.resolve(response);
    }

    @action
    update = async (body: CandidateModelCreate) => {
        const response: any = await DataHandlerStore.post(candidateEndpoint.updateParticipant.name, body);
        this.data = [];
        return Promise.resolve(response);
    };

    @action
    setStatus = (value) => {
        this.screenVisitDateSelected = value === 'screen-visit-booked';
    };

    @action
    resetFilters = async () => {
        DataTableStore.resetSelectedPage();
        this.filterArray = [];
        this.showInfoPanel = false;
        this.initialTab = 0;
        this.current = {};

        const request: ICandidateLoadRequest = {
            id: '',
            filter: {},
            currentTabArg: this.currentTab
        }

        await this.load(request);
        this.closeModal();
    }

    @action
    getExportData = async () => {
        const response: any = await DataHandlerStore.get(candidateEndpoint.getExportData.name);
        this.exportData = response.data.clients;
        if (this.exportData.length === 1) {
            this.filterStudiesByClient(this.exportData[0].clientId);
        }
    }

    @action
    postExportData = async (body) => {
        body.isdownloadZip = body && body.studies && body.studies.length && body.fromDate && body.toDate;
        const response: any = await DataHandlerStore.post(candidateEndpoint.postExportData.name, body);
        downloadjs(new Blob([response]), 'faze-data-export.zip');
    };

    @action
    postExportIneligibleCandidateData = async (body) => {
        body.isdownloadZip = body && body.studies && body.studies.length && body.allDates;
        const response: any = await DataHandlerStore.post(candidateEndpoint.postExportIneligibleCandidateData.name, body);
        downloadjs(new Blob([response]), 'faze-data-ineligible-export.zip');
    };

    @action
    postExtractData = async (body) => {
        body.isdownloadZip = body && body.reportId;
        const response: any = await DataHandlerStore.post(candidateEndpoint.postExtractData.name, body);
        downloadjs(new Blob([response]), 'faze-data-extract.zip');
    };

    filterStudiesByClient = (clientId) => {
        this.filteredStudies = [];
        if (!clientId) {
            return;
        }

        const client = this.exportData.filter(item => item.clientId === clientId);
        if (client && client.length) {
            this.filteredStudies = client[0].studies.map(item => {
                const newItem = item;
                newItem.checked = false;
                return newItem;
            });
        }
    }

    updateDataUploadSteps = ({ client, studies, fromDate, toDate }) => {
        if (client) {
            this.dataExport.client = client;
        }
        if (studies) {
            this.dataExport.studies = studies;
        }
        if (fromDate) {
            this.dataExport.fromDate = fromDate;
        }
        if (toDate) {
            this.dataExport.toDate = toDate;
        }

        this.dataExportIsComplete = this.dataExport.studies && this.dataExport.fromDate && this.dataExport.toDate;
        this.dataIneligibleExportIsComplete = this.dataExport.studies;
    }

    resetDataExport = () => {
        this.dataExport = { client: null, studies: null, date: null };
        this.dataExportIsComplete = false;
        this.dataIneligibleExportIsComplete = false;
        this.filteredStudies = [];
        this.getExportData();
    }



    updateDataExtract = (name: string, value: string | boolean) => {
        this.dataExtract[name] = value;
        if (!this.dataExtract?.reportId || this.dataExtract?.reportId === '') {
            this.dataExtractIsComplete = false;
        } else if (this.dataExtract.fromDate && this.dataExtract.toDate || (this.dataExtract.allDates && this.dataExtract.allDates !== false)) {
            this.dataExtractIsComplete = true;
        } else {
            this.dataExtractIsComplete = false;
        }
    }
}

export default new CandidateStore();
