import DataHandlerStore from '../@stores/DataHandlerStore';
import { candidateEndpoint } from '../@helpers/config';

export class ExclusionReasonsFactory {
    static async getExclusionReasons(studyId) {
        const key: string = `static-exclusion-${studyId}`;
        const lsValue = localStorage.getItem(key);

        if (lsValue) {
            return JSON.parse(lsValue);
        } else {
            const response: any = await DataHandlerStore.get(candidateEndpoint.getExclusionReasons.name, studyId);
            const { data } = response;
            try {
                localStorage.setItem(`static-exclusion-${studyId}`, JSON.stringify(data));
            } catch (error) {
                for (let i = 0; i < localStorage.length; i++) {
                    if (localStorage.key[i].includes('static-exclusion-')) {
                        localStorage.removeItem(localStorage.key[i]);
                    }
                }
                localStorage.setItem(`static-exclusion-${studyId}`, JSON.stringify(data));
            }

            return data;
        }
    }
}
