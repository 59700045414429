import axios from 'axios';
import Config from '@helpers/config';

const formatData = function ({ action, body, id, options, params }) {
  const base = {
    data: {},
    url: '',
    headers: {
      'Content-Type': 'application/json',
      authorization: '',
    },
    ...options,
  };

  if (!action) {
    return base;
  }

  if (body && body.isdownloadZip) {
    base.headers.Accept = 'application/zip';
    base.responseType = 'arraybuffer';

    delete body.isdownloadZip;
  }

  const endpointUrl = Config.apiEndpoint[action].endpoint;

  base.url = `${Config.apiEndpoint.base + endpointUrl}`;

  if (id) {
    base.url += id;
  }

  if (params && params.length) {
    base.url += params.join('/');
  }

  const token = localStorage.getItem('token');

  if (token && token.length) {
    base.headers.authorization = `Bearer ${token}`;
  }

  if (body) {
    base.data = body;
  }

  return {
    ...base,
    method: Config.apiEndpoint[action].method,
  };
}

export const apiRequest = function (data) {
  return axios(formatData(data));
};
