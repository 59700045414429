import { observable, action } from 'mobx';

import BaseStore from './BaseStore';

export class InfoPanelStoreBlueprint extends BaseStore {
  @observable height: any = undefined;
  @observable top: any = undefined;

  @action
  resize = (calculatedHeight, mastheadHeight) => {
    this.height = calculatedHeight;
    this.top = mastheadHeight;
  }
}

const InfoPanelStore = new InfoPanelStoreBlueprint();
export default InfoPanelStore;
