import { candidateEndpoint, listEndpoint } from '@helpers/config';
import { SearchType } from '@helpers/SearchType';
import { constructForSelect, getRefValue, getToggleValue, getValue, selectOptionNoValue } from '@helpers/util';
import { getUserById } from 'api/user';
import { action, computed, observable } from 'mobx';
import Toggle from 'react-toggle';
import { CANDIDATE_STATUS_EXCLUDED } from '../@helpers/candidateStatus';
import { ExclusionReasonsFactory } from '../domain/ExclusionReasonsFactory';
import { StatusFactory } from '../domain/StatusFactory';
import DataHandlerStore from './DataHandlerStore';

interface StoreDefault {
    current: {
        id: { value: any; },
    };
    update: (arg0: any) => void;
    create: (arg0: any) => void;
}

const excludeKeysForUser = ['sitesList', 'role'];

class BaseStore {
    @observable showMasthead: boolean = true;
    @observable currentScroll: number = 0;
    @observable mastheadHeight: number = 0;
    @observable showModal: boolean = false;
    @observable current: any = {};
    @observable showInfoPanel: boolean = false;
    @observable initialTab: number = 0;
    @observable contactOptions: any = [];
    @observable countries: any = [];
    @observable ethnicities: any = [];
    @observable statuses: any = [];
    @observable priorities: any = [];
    @observable exclusions: any = [];
    @observable exclusionsForStudy: any = [];
    @observable exclusionReason: any = {};
    @observable exclusionStatus: any = {};
    @observable actionRequired: boolean = false;
    @observable reasonSelected: boolean = false;
    @observable currentAccess: any = { value: undefined, hasAccess: false };
    @observable siteAccess: any = [];
    @observable extracts: any = [];

    @action
    closeModal = () => {
        this.showModal = false;
    };

    @action
    createNew = async () => {
        if (!this.current) {
            return;
        }

        delete this.current.id;
        delete this.current.created;

        Object.keys(this.current).forEach((key) => {
            const currentElement = this.current[key];
            if (!currentElement.editable) {
                if (!['contractTypes', 'contractMessage', 'studiesList'].includes(key)) {
                    delete this.current[key];
                }
            }

            if (currentElement.display === 'Site Address' || currentElement.display === 'Company Address' || currentElement.display === 'Contact Address') {
                this.current[key]['value'] = {
                    addressLine1: '',
                    addressLine2: '',
                    addressTown: '',
                    addressRegion: '',
                    addressCountry: '',
                    addressPostcode: ''
                }
                return;
            }
            if (excludeKeysForUser.includes(key)) {
                return;
            }

            if (this.current[key] && this.current[key]['value']) {
                if (!['contractType', 'contractTypes', 'contractMessage', 'studiesList'].includes(key)) {
                    delete this.current[key]['value'];
                }

            }
        });
    };

    @action
    handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > this.currentScroll) {
            if (scrollTop > 0) {
                this.showMasthead = false;
            }
        } else {
            this.showMasthead = true;
        }
        this.currentScroll = scrollTop;
    };

    @action
    getParticipantById = async (id: string) => {
        const { getAsTable } = DataHandlerStore;
        const response: any = await getAsTable(candidateEndpoint.getParticipantById.name, id);
        return Promise.resolve(response);
    };

    @action
    getUserById = async (id: string) => {
        const response: any = await getUserById(id);
        return Promise.resolve(response);
    };

    @action
    getEthnicities = async () => {
        const cached = localStorage.getItem('ethnicities');
        if (cached) {
            const parsedEthnicities = JSON.parse(cached);
            this.ethnicities = parsedEthnicities;
            return parsedEthnicities
        }
        const { get } = DataHandlerStore;
        const response: any = await get(listEndpoint.getEthnicities.name);
        const ethnicities = response.data.map((datum: any) => {
            const item = {
                id: datum.code,
                name: datum.description,
            };
            return constructForSelect(item);
        });
        localStorage.setItem('ethnicities', JSON.stringify(ethnicities));
        this.ethnicities = ethnicities;
        return Promise.resolve(response);
    };

    @action
    getCountries = async () => {
        const cachedCountries = localStorage.getItem('countries');
        if (cachedCountries) {
            const parseCountries = JSON.parse(cachedCountries);
            this.countries = parseCountries;
            return parseCountries
        }
        const { get } = DataHandlerStore;
        const response: any = await get(listEndpoint.getCountries.name);
        const countries = response.data.map((datum: any) => {
            const item = {
                id: datum.abbreviation,
                name: datum.country,
            };
            return constructForSelect(item);
        });
        localStorage.setItem('countries', JSON.stringify(countries));
        this.countries = countries;
        return Promise.resolve(response);
    };

    @action
    getContactOptions = async () => {
        const cachedContactOptions = localStorage.getItem('contactOptions');
        if (cachedContactOptions) {
            const parseContactOptions = JSON.parse(cachedContactOptions);
            this.contactOptions = parseContactOptions;
            return parseContactOptions
        }
        const { get } = DataHandlerStore;
        const response: any = await get(listEndpoint.getContactOptions.name);
        const contactOptions = response.data.map((datum: any) => {
            const item = {
                id: datum.code,
                name: datum.description,
            };
            return constructForSelect(item);
        });
        localStorage.setItem('contactOptions', JSON.stringify(contactOptions));
        this.contactOptions = contactOptions;
        return Promise.resolve(response);
    };

    @action
    getExclusionReasons = async (studyId) => {
        this.exclusionsForStudy = await ExclusionReasonsFactory.getExclusionReasons(studyId);

        return this.exclusions;
    };

    @action
    getStatuses = async (studyId) => {
        this.statuses = await StatusFactory.getStatuses(studyId);

        return this.statuses;
    };

    @action
    getPriorities = async (studyId) => {
        this.priorities = await StatusFactory.getPriorityStatuses(studyId);

        return this.priorities;
    };

    @action
    getParticipant = async (id: string) => {
        const response: any = await DataHandlerStore.get(candidateEndpoint.getParticipantById.name, id);
        const { data } = response;
        this.current = data;
        return Promise.resolve(response);
    };

    @action
    getParticipantsByStudySiteId = async (id: string) => {
        const { getAsTable: get } = DataHandlerStore;
        const response: any = await get(candidateEndpoint.getParticipantsByStudySiteId.name, id);
        return Promise.resolve(response);
    };

    @action 
    getExtracts = async () => {
        const cachedExtracts = localStorage.getItem('extracts');
        if (cachedExtracts) {
            const parseExtracts = JSON.parse(cachedExtracts);
            this.extracts = parseExtracts;
            return parseExtracts;
        }
        const { get } = DataHandlerStore;
        const response: any = await get(listEndpoint.getExtracts.name);
        const extracts = response.data.map((datum: any) => {
            const item = {
                id: datum.reportId,
                name: datum.label,
            };
            return constructForSelect(item);
        });
        localStorage.setItem('extracts', JSON.stringify(extracts));
        this.extracts = extracts;
        return Promise.resolve(response);
    }

    @action
    navigate = (history: any, path: string) => {
        if (history) {
            history.push(path);
        } else {
            window.location.pathname = path;
        }
    };

    @action
    openModal = () => {
        this.showModal = true;
    };

    @action('opens up a side modal')
    handleOpenInfoPanel = async (id: any) => {
        const response: any = await this.getParticipantById(id);
        this.showInfoPanel = !this.showInfoPanel;
        this.current = response;
        this.initialTab = 0;
        return Promise.resolve(response);
    };

    @action
    handleCloseInfoPanel = () => {
        this.showInfoPanel = false;
        this.initialTab = 0;
        DataHandlerStore.updateShowError(false);
        document.documentElement.style.overflow = '';
    };

    @action('opens up a side modal')
    handleOpenUserInfoPanel = async (id: any) => {
        const response: any = await this.getUserById(id);
        this.showInfoPanel = !this.showInfoPanel;
        this.current = response;
        this.initialTab = 0;
        return Promise.resolve(response);
    };

    @action
    handleCloseUserInfoPanel = () => {
        this.showInfoPanel = false;
        this.initialTab = 0;
        DataHandlerStore.updateShowError(false);
        document.documentElement.style.overflow = '';
    };

    @action
    canViewDetails = () => {
        return this.isAdmin || this.isSuperUser || this.isCC || this.isInvestigator;
    };

    @action
    setShowInfoPanel = (newState: boolean) => {
        this.showInfoPanel = newState;
    };

    @action
    setExclusionReason = (reasonValue) => {
        this.exclusionStatus = {
            id: getValue(this.current.id),
            status: CANDIDATE_STATUS_EXCLUDED,
            reasonType: reasonValue,
            optionalDetail: '',
        };

        this.current.rejection.value = reasonValue;
    };

    setOtherExclusionReason = (otherReason) => {
        this.exclusionStatus = {
            ...this.exclusionStatus,
            optionalDetail: otherReason,
        };

        this.current.reasonOptionalDetail.value = otherReason;
    };

    setStatus = (status) => {
        this.current.status.value = status;
    }

    @action
    onReasonSelected = (value) => {
        if (!Object.keys(this.exclusionStatus).length) {
            this.setExclusionReason(getValue(this.exclusions[0]));
        }

        this.reasonSelected = value;
        this.actionRequired = false;
    };

    @action
    resetExcludedFlags = () => {
        this.reasonSelected = false;
        this.actionRequired = false;
    };

    @action
    getAccess = (UserStore) => {
        const isEditNew = window.location.href.includes('edit/new');
        if (isEditNew) {
            this.resetAccess(UserStore);
            return;
        }

        const { current: { access } } = UserStore;
        const dataAccess = access;

        if (dataAccess && dataAccess.value) {
            const { clientId, relatedId: studyId } = access.value[0];
            this.currentAccess = { value: dataAccess.value, hasAccess: true, clientId, studyId };
        }
    }

    @action
    dynamicModel = (component: any, current: any): any => {
        const model = {};
        Object.keys(current).map((key: any) => {
            const item = component[key];
            let value = getRefValue(item);

            if (!component[key]) {
                value = current[key].value
            }

            switch (key) {
                case 'enabled':
                    if (item instanceof Toggle) {
                        if (key === 'enabled') {
                            model[key] = getToggleValue(item);
                        }
                    }
                    break;
                case 'siteAddress':
                    model[key] = this.draftAddressFields(current, key, component);
                    break;
                case 'contactAddress':
                    model[key] = this.draftAddressFields(current, key, component);
                    break;
                case 'companyAddress':
                    model[key] = this.draftAddressFields(current, key, component);
                    break;
                case 'principalInvestigator':
                    model[key] = value === undefined ? '' : value;
                    break;
                case 'websiteResponses':
                    const numberOfItems = current[key].value.length || 0;

                    const webResponses = Array.from(Array(numberOfItems).keys()).map((_, index) => {
                        let item = component[key + (index + 1)];

                        if (!component[key]) {
                            item = current[key].value[index];
                        }

                        return {
                            question: current[key].value[index].question,
                            response: item.response
                        };
                    });

                    if (webResponses.length) {
                        model[key] = webResponses;
                    }
                    break;
                case 'dob':
                    // model[key] = moment(value).format('YYYY-MM-DD');
                    model[key] = value;
                    break;
                case 'studySiteId':
                    if (value === null) {
                        model[key] = {
                            "currentId": null,
                            "list": []
                        };
                    } else {
                        model[key] = value
                    }
                    break;
                case 'returnToParent':
                        model[key] = value;
                    break;
                case 'sitesList': {
                    if (!this.isFormCreation) {
                        break;
                    }
                    const clientIdKey = 'clientId';
                    if (value && value.data && value.data.length) {
                        model[clientIdKey] = '';
                    } else {
                        model[clientIdKey] = value;
                    }

                    break;
                }
                case 'access': {
                    this.createAccessField(model);
                    break;
                }
                case 'studies': {
                    if (value === undefined) {
                        model[key] = [];
                    } else {
                        model[key] = value;
                    }
                    break;
                }
                default:
                    if (value === undefined) {
                        model[key] = '';
                    } else {
                        model[key] = value;
                    }
                    if (current && current.id) {
                        this.exclusionDataSetup(key, model, current, value);
                    }
            }
        });
        Object.keys(model).forEach(key => {
            const curr = model[key];
            if (curr === selectOptionNoValue) {
                model[key] = null;
            }
        });

        if (!model['access'] && this.currentAccess.relatedId) {
            this.createAccessField(model);
        }
        return model;
    };

    @action
    addSelectToDropDown = (options) => {
        const selectOption = { id: '', name: 'Select an option' };
        options.pus(selectOption);
        return options;
    };

    @action
    async genericSubmit(component: any, store: StoreDefault, match: { params: { id: any; }; }, navigateAction: () => void) {
        const model = this.dynamicModel(component, store.current);
        if (match && match.params.id && match.params.id !== 'new') {
            model['id'] = model['id'] || store.current.id.value || match.params.id;
            await store.update(model)
        } else {
            await store.create(model);
        }

        navigateAction();
    }

    @action
    filterByQuery = async ({ searchQuery, searchType, store }) => {
        const toLowerCase = value => value ? value.toLowerCase() : value;
        const query = searchQuery.toLowerCase().trim().replace(/\s+/g, ' ');
        const splitQuery = query.split(' ');

        if (searchType === SearchType.Study) {
            store.dataAsTable.rows = store.data.filter(item => {
                return (
                    toLowerCase(item.ccName.value).includes(query)
                    || toLowerCase(item.idCode.value).includes(query)
                    || toLowerCase(item.clientName.value).includes(query)
                    || toLowerCase(item.name.value).includes(query)
                )
            });
            return;
        }

        if (searchType === SearchType.Site) {
            store.dataAsTable.rows = store.data.filter(item => {
                return (
                    toLowerCase(item.name.value).includes(query)
                    || toLowerCase(item.siteIdentifier.value).includes(query)
                )
            });
            return;
        }

        if (searchType === SearchType.Candidate) {
            store.dataAsTable.rows = store.data.filter(item => {
                return (
                    toLowerCase(item.firstName ? item.firstName.value : '').includes(query)
                    || toLowerCase(item.surname ? item.surname.value : '').includes(query)
                    || toLowerCase(item.idCode.value).includes(query)
                )
            });
        }

        const checkFullName = (item) => {
            const firstName = (toLowerCase(item.firstName ? item.firstName.value : ''));
            const surname = toLowerCase(item.surname ? item.surname.value : '');

            return splitQuery.some(val => firstName.includes(val) || surname.includes(val));
        }

        if (searchType === SearchType.User) {
            store.dataAsTable.rows = store.data.filter(item => {
                return (
                    toLowerCase(item.firstName ? item.firstName.value : '').includes(query)
                    || toLowerCase(item.surname ? item.surname.value : '').includes(query)
                    || toLowerCase(item.email ? item.email.value : '').includes(query)
                    || checkFullName(item)
                )
            });
        }
    }

    @computed
    get currentUser() {
        const token: any = localStorage.getItem('current-user');
        if (!token) {
            return undefined;
        }
        return JSON.parse(token);
    }

    @computed
    get isSuperUser() {
        const curr = this.currentUser;
        return curr && curr.role === 'superuser';
    }

    @computed
    get isAdmin() {
        const curr = this.currentUser;
        return curr && curr.role === 'administrator';
    }

    @computed
    get isCC() {
        const curr = this.currentUser;
        return curr && curr.role === 'cc-user';
    }

    @computed
    get isFormCreation() {
        return window.location.pathname.includes('/edit/new');
    }

    @computed
    get isInvestigator() {
        const curr = this.currentUser;
        return curr && curr.role === 'investigator';
    }

    // Local helpers
    exclusionDataSetup = (key, model, current, value) => {
        const keyIsStatus = key === 'status';
        if (keyIsStatus) {
            model[key] = {
                id: getValue(current.id),
                status: value,
                reasonType: '',
                optionalDetail: '',
            };
            if (value === CANDIDATE_STATUS_EXCLUDED) {
                model[key] = this.exclusionStatus;
            }
        }
        if (!this.reasonSelected && keyIsStatus && value === CANDIDATE_STATUS_EXCLUDED) {
            model[key] = this.exclusionStatus;
            this.actionRequired = true;
            this.setShowInfoPanel(true);
        }
    }

    draftAddressFields = (current, key, component) => {
        const siteAddress = current[key];
        const siteAddressVal = siteAddress.value;
        const siteModel = {};

        siteAddress && siteAddressVal && Object.keys(siteAddressVal).forEach((field: any) => {
            const addressItem = component[key + field];
            siteModel[field] = getRefValue(addressItem);
        });

        return siteModel;
    }

    createAccessField = (model) => {
        model['access'] = this.siteAccess.map(item => {
                return {
                    relatedId: item.relatedId,
                    sites: (item.sites) ? item.sites.map(site => ({ relatedId: site.siteId })) : []
                }
            }
        );
    }

    resetAccess = (UserStore) => {
        this.currentAccess = {
            hasAccess: true,
            value: null,
            clientId: null,
            studyId: null
        }
        UserStore.currentAccess = this.currentAccess;
    }

    reset = () => {
        this.current = {};
        this.closeModal();
    };
}

export default BaseStore;
